import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { InjectionKey } from 'vue';
import { Auction } from '@/staff/domain/exclusive/Auction';
import { BidWithExclusive } from '@/staff/domain/exclusive/BidWithExclusive';
import { AuctionId } from '@/staff/domain/exclusive/AuctionId';
import { AuctionToCreate } from '@/staff/domain/exclusive/AuctionToCreate';
import { AuctionToUpdate } from '@/staff/domain/exclusive/AuctionToUpdate';

export const auctionRepositoryKey: InjectionKey<AuctionRepository> = Symbol();

export interface AuctionRepository {
  getById(clubSlug: ClubSlug, auctionId: AuctionId): Promise<Auction>;
  listByClub(clubSlug: ClubSlug): Promise<Auction[]>;
  listAuctionBids(clubSlug: ClubSlug, auctionId: AuctionId): Promise<BidWithExclusive[]>;
  create(clubSlug: ClubSlug, auctionToCreate: AuctionToCreate): Promise<void>;
  update(auctionToUpdate: AuctionToUpdate): Promise<Auction>;
}
