import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { AuctionRepository } from '@/staff/domain/exclusive/AuctionRepository';
import { Auction } from '@/staff/domain/exclusive/Auction';
import { BackendAuction, toAuction } from '@/staff/secondary/exclusive/BackendAuction';
import { AuctionId } from '@/staff/domain/exclusive/AuctionId';
import { BidWithExclusive } from '@/staff/domain/exclusive/BidWithExclusive';
import { BackendBidWithExclusive, toBidWithExclusive } from '@/staff/secondary/exclusive/BackendBidWithExclusive';
import { AuctionToCreate } from '@/staff/domain/exclusive/AuctionToCreate';
import { toBackendAuctionToCreate } from '@/staff/secondary/exclusive/BackendAuctionToCreate';
import { AuctionToUpdate } from '@/staff/domain/exclusive/AuctionToUpdate';
import { toBackendAuctionToUpdate } from '@/staff/secondary/exclusive/BackendAuctionToUpdate';

export class BackendAuctionRepository implements AuctionRepository {
  constructor(private backendCaller: BackendCaller) {}

  getById(clubSlug: ClubSlug, auctionId: AuctionId): Promise<Auction> {
    return this.backendCaller
      .get<BackendAuction>(`api/clubs/${clubSlug}/auctions/${auctionId}`, { params: { 'with-translations': true } })
      .then(auctions => toAuction(auctions.data));
  }

  listByClub(clubSlug: ClubSlug): Promise<Auction[]> {
    return this.backendCaller.get<BackendAuction[]>(`api/clubs/${clubSlug}/auctions`).then(auctions => auctions.data.map(toAuction));
  }

  listAuctionBids(clubSlug: ClubSlug, auctionId: AuctionId): Promise<BidWithExclusive[]> {
    return this.backendCaller
      .get<BackendBidWithExclusive[]>(`api/clubs/${clubSlug}/auctions/${auctionId}/bids`)
      .then(bids => bids.data.map(toBidWithExclusive));
  }

  async create(clubSlug: ClubSlug, auctionToCreate: AuctionToCreate): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${clubSlug}/auctions`, toBackendAuctionToCreate(auctionToCreate));
  }

  update(auctionToUpdate: AuctionToUpdate): Promise<Auction> {
    return this.backendCaller
      .patch<BackendAuction>(
        `api/clubs/${auctionToUpdate.clubSlug}/auctions/${auctionToUpdate.id}`,
        toBackendAuctionToUpdate(auctionToUpdate)
      )
      .then(auction => toAuction(auction.data));
  }
}
