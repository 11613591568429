import {
  BackendGiveawayAnswerToUpdate,
  toBackendGiveawayAnswerToUpdate,
} from '@/staff/secondary/club/giveaway/BackendGiveawayAnswerToUpdate';
import { GiveawayQuestionToUpdate } from '@/staff/domain/club/giveaway/GiveawayQuestionToUpdate';
import { BackendTranslation, toBackendTranslation } from '@/common/secondary/BackendTranslation';
import { GiveawayQuestionTitle } from '@/staff/domain/club/giveaway/GiveawayQuestionTitle';
import { GiveawayQuestionPreTitle } from '@/staff/domain/club/giveaway/GiveawayQuestionPreTitle';

export interface BackendGiveawayQuestionToUpdate {
  id?: string;
  answers: BackendGiveawayAnswerToUpdate[];
  required: boolean;
  rightAnswerText?: string;
  quizMode: boolean;
  multiline: boolean;
  titleTranslations: BackendTranslation[];
  preTitleTranslations?: BackendTranslation[];
}

export const toBackendGiveawayQuestionToUpdate = (giveawayQuestionToUpdate: GiveawayQuestionToUpdate): BackendGiveawayQuestionToUpdate => ({
  id: giveawayQuestionToUpdate.id.orElseUndefined(),
  answers: giveawayQuestionToUpdate.answers.map(toBackendGiveawayAnswerToUpdate),
  required: giveawayQuestionToUpdate.required,
  rightAnswerText: giveawayQuestionToUpdate.rightAnswer.map(rightAnswer => rightAnswer.text).orElseUndefined(),
  quizMode: giveawayQuestionToUpdate.quizMode,
  multiline: giveawayQuestionToUpdate.multiline,
  titleTranslations: [
    toBackendTranslation<GiveawayQuestionTitle>({
      language: 'fr',
      value: giveawayQuestionToUpdate.title,
    }),
  ],
  preTitleTranslations: giveawayQuestionToUpdate.preTitle
    .map(preTitle => [
      toBackendTranslation<GiveawayQuestionPreTitle>({
        language: 'fr',
        value: preTitle,
      }),
    ])
    .orElseUndefined(),
});
