import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { Auction } from '@/staff/domain/exclusive/Auction';
import { fromAuction } from '@/staff/primary/exclusive/Auction.ui';

export default defineComponent({
  name: 'AuctionCard',

  props: {
    isSelected: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    auction: {
      type: Object as PropType<Auction>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();

    const auctionUi = computed(() => fromAuction(props.auction));

    const onAuctionCardClick = () => emit('selected');

    return {
      auctionUi,
      onAuctionCardClick,
      t,
    };
  },
});
