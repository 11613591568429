import { alertBusKey } from '@/common/domain/alert/AlertBus';
import router from '@/router/router';
import { ClubFormUi, fromClub, toClubToUpdate } from '@/staff/primary/club/club-form/ClubForm.ui';
import { defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ClubFormVue } from '@/staff/primary/club/club-form';
import { FairplayerButtonVue } from '@/common/primary/button';
import { PageVue } from '@/staff/primary/club-layout/page';
import { loggerKey } from '@/common/domain/Logger';
import { isClubToUpdateEmpty } from '@/staff/domain/club/ClubToUpdate';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';

export default defineComponent({
  name: 'UpdateClub',

  components: {
    ClubFormVue,
    FairplayerButtonVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const club = clubRepository.getCurrentClub();
    const alertBus = inject(alertBusKey)!;
    const clubForm = ref(fromClub(club, club.languages));

    const onConfirmUpdate = (updatedClubForm: ClubFormUi): Promise<void> => {
      const clubToUpdate = toClubToUpdate(updatedClubForm, club);
      if (isClubToUpdateEmpty(clubToUpdate)) {
        return Promise.resolve();
      }

      return clubRepository
        .update(clubToUpdate)
        .then(updatedClub => {
          clubForm.value = fromClub(updatedClub, updatedClub.languages);
          alertBus.alert('toasts.success.club.update', 'success');
          router.push({ name: 'updateClub', params: { clubSlug: props.clubSlug } });
        })
        .catch(error => logger.error('Failed to update club', error));
    };

    const onGoBack = () => {
      router.push({ name: 'clubOverview', params: { clubSlug: props.clubSlug } });
    };

    return {
      clubForm,
      club,
      onGoBack,
      onConfirmUpdate,
      t,
    };
  },
});
