import { DraftDescriptionRequest } from '@/staff/domain/completion/DraftDescriptionRequest';

export interface BackendDraftDescriptionRequest {
  name: string;
  context?: string;
}

export const toBackendDraftDescriptionRequest = (draftDescriptionRequest: DraftDescriptionRequest): BackendDraftDescriptionRequest => ({
  name: draftDescriptionRequest.name,
  context: draftDescriptionRequest.context.orElseUndefined(),
});
