import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { BreadcrumbSegmentUi } from '@/common/primary/breadcrumb/BreadcrumbSegment.ui';

export default defineComponent({
  name: 'Breadcrumb',

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    platformName: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t, te } = useI18n();
    const router = useRouter();

    const path = computed(() => router.currentRoute.value.path);

    const pathArray = computed(() => path.value.split('/').slice(1));

    const breadcrumbSegments = computed(() => {
      const initialSegment: BreadcrumbSegmentUi = { route: `/clubs/${props.clubSlug}`, label: props.platformName };
      const segments: BreadcrumbSegmentUi[] = [initialSegment];
      const paths = pathArray.value.slice(2);

      const isUuid = (segment: string): boolean => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(segment);

      const getLabel = (segment: string): string => {
        if (isUuid(segment)) {
          return segment.split('-')[0];
        }
        if (hasTranslation(segment)) {
          return t(`breadcrumb.${segment}`);
        }
        return segment;
      };

      paths.reduce((accumulatedPath, segment) => {
        const route = `${accumulatedPath}/${segment}`;
        const label = getLabel(segment);
        segments.push({ route, label });
        return route;
      }, initialSegment.route);

      return segments;
    });

    const hasTranslation = (segment: string) => te(`breadcrumb.${segment}`);

    const isCurrentSegment = (index: number) => index === breadcrumbSegments.value.length - 1;

    return { breadcrumbSegments, isCurrentSegment };
  },
});
