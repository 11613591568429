import { PostToUpdate } from '@/staff/domain/post/PostToUpdate';
import { BackendMedia, toBackendMedia } from '@/staff/secondary/BackendMedia';
import { BackendTranslation, toBackendTranslation } from '@/common/secondary/BackendTranslation';
import { PostName } from '@/staff/domain/post/PostName';
import { PostDescription } from '@/staff/domain/post/PostDescription';
import { toBackendArray } from '@/staff/secondary/toBackendArray';

export interface BackendPostToUpdate {
  slug?: string;
  imageUrl?: string;
  date?: string;
  medias?: BackendMedia[];
  nameTranslations?: BackendTranslation[];
  descriptionTranslations?: BackendTranslation[];
}

export const toBackendPostToUpdate = (postToUpdate: PostToUpdate): BackendPostToUpdate => ({
  slug: postToUpdate.slug.orElseUndefined(),
  imageUrl: postToUpdate.imageUrl.orElseUndefined(),
  date: postToUpdate.date.map(date => date.toISOString()).orElseUndefined(),
  medias: toBackendArray(postToUpdate.medias, toBackendMedia),
  nameTranslations: toBackendArray(postToUpdate.nameTranslations, toBackendTranslation<PostName>),
  descriptionTranslations: toBackendArray(postToUpdate.descriptionTranslations, toBackendTranslation<PostDescription>),
});
