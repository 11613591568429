export enum RouterTab {
  OVERVIEW = 'OVERVIEW',
  GIVEAWAYS = 'GIVEAWAYS',
  EXCLUSIVES = 'EXCLUSIVES',
  AUCTIONS = 'AUCTIONS',
  COINS = 'COINS',
  PROGRAMS = 'PROGRAMS',
  POSTS = 'POSTS',
  FANS = 'FANS',
  CLUB = 'CLUB',
}
