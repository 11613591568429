import { Optional } from '@/common/domain/Optional';

export const dragStart = (event: DragEvent, value: object) => {
  if (!event || !event.dataTransfer) {
    return;
  }

  event.dataTransfer.setData('application/json', JSON.stringify(value));
  event.dataTransfer.effectAllowed = 'copy';
};

export const dragOver = async (event: DragEvent) => {
  if (!event || !event.dataTransfer) {
    return;
  }

  if (event.dataTransfer.types.includes('application/json')) {
    event.dataTransfer.dropEffect = 'copy';
  }
};

export const drop = <T>(event: DragEvent): Optional<T> =>
  Optional.ofUndefinable(event?.dataTransfer)
    .filter(dataTransfer => dataTransfer.types.includes('application/json'))
    .map(dataTransfer => JSON.parse(dataTransfer.getData('application/json')));
