import { ClubState } from '@/staff/domain/club/ClubState';
import { fromGiveaway, GiveawayUi } from '@/staff/primary/giveaway/giveaways-table/Giveaway.ui';
import { Club } from '@/staff/domain/club/Club';
import { Coin } from '@/common/domain/token/Coin';
import { ClubModel } from '@/staff/domain/club/ClubModel';

export interface ClubUi {
  name: string;
  platformName: string;
  slug: string;
  isFoundation: boolean;
  isMarketplace: boolean;
  logoUrl: string;
  bannerUrl: string;
  webappUrl: string;
  coin: Coin;
  state: ClubState;
  referralNeeded: boolean;
  coinsEnabled: boolean;
  fiatAccepted: boolean;
  activeGiveaways: GiveawayUi[];
}

export const fromClub = (club: Club): ClubUi => ({
  name: club.name,
  platformName: club.platformName,
  slug: club.slug,
  isFoundation: club.model === ClubModel.FOUNDATION,
  isMarketplace: club.model === ClubModel.MARKETPLACE,
  logoUrl: club.logoUrl,
  bannerUrl: club.bannerUrl,
  webappUrl: club.webappUrl,
  coin: club.coin,
  state: club.state,
  referralNeeded: club.referralNeeded,
  coinsEnabled: club.coinsEnabled,
  fiatAccepted: club.fiatAccepted,
  activeGiveaways: club.activeGiveaways.map(fromGiveaway),
});
