import { AuctionLotToCreate } from '@/staff/domain/exclusive/AuctionLotToCreate';

export interface BackendAuctionLotToCreate {
  auctionId: string;
  number?: number;
}

export const fromAuctionLotToCreate = (auctionLot: AuctionLotToCreate): BackendAuctionLotToCreate => ({
  auctionId: auctionLot.auctionId,
  number: auctionLot.number.orElseUndefined(),
});
