include ../../../club/loading-table.mixin.pug

.fp-responsive-grid.-cols2.-cols1-up-sm
  .fp-responsive-grid--column
    .fp-card.-highlighted
      .fp-vertical-spacing.-s-xs.-justify-center.-h100
        .fp-vertical-spacing--line.-align-center
          i.fp-icon.fp-glyph-gavel.-s24.-color-shade-500
        .fp-vertical-spacing--line.-align-center
          .fp-placeholder.-height-sm(v-if='bids.isLoading()' data-test='loading-bids')
          .fp-text.-text-semi-bold.-text-2xl(v-else data-test='bids') {{ bids.value().length }}
        .fp-vertical-spacing--line.-align-center
          .fp-text.-text-semi-bold.-text-sm {{ t('auctionsPage.statistics.bids') }}
  .fp-responsive-grid--column
    .fp-card.-highlighted
      .fp-vertical-spacing.-s-xs.-justify-center.-h100
        .fp-vertical-spacing--line.-align-center
          i.fp-icon.fp-glyph-user.-s24.-color-shade-500
        .fp-vertical-spacing--line.-align-center
          .fp-placeholder.-height-sm(v-if='bids.isLoading()')
          .fp-text.-text-semi-bold.-text-2xl(v-else data-test='bidders') {{ bidders }}
        .fp-vertical-spacing--line.-align-center
          .fp-text.-text-semi-bold.-text-sm {{ t('auctionsPage.statistics.bidders') }}
  .fp-responsive-grid--column
    .fp-card.-highlighted
      .fp-vertical-spacing.-s-xs.-justify-center.-h100
        .fp-vertical-spacing--line.-align-center
          i.fp-icon.fp-glyph-shopping-bag.-s24.-color-shade-500
        .fp-vertical-spacing--line.-align-center
          .fp-placeholder.-height-sm(v-if='bids.isLoading() || clubExclusives.isLoading()')
          .fp-text.-text-semi-bold.-text-2xl(v-else data-test='exclusives-with-bids')
            | {{ exclusivesWithBids }}
            span.fp-text.-text-semi-bold.-text-md /{{ auctionExclusives.length }}
          .fp-vertical-spacing--line.-align-center
            .fp-text.-text-semi-bold.-text-sm {{ t('auctionsPage.statistics.exclusivesWithBids') }}
  .fp-responsive-grid--column
    .fp-card.-highlighted
      .fp-vertical-spacing.-s-xs.-justify-center.-h100
        .fp-vertical-spacing--line.-align-center
          i.fp-icon.fp-glyph-euro.-s24.-color-shade-500
        .fp-vertical-spacing--line.-align-center
          .fp-placeholder.-height-sm(v-if='clubExclusives.isLoading()')
          .fp-text.-text-semi-bold.-text-2xl(v-else data-test='total')
            | {{ total }}
            span.fp-text.-text-semi-bold.-text-md €
        .fp-vertical-spacing--line.-align-center
          .fp-text.-text-semi-bold.-text-sm {{ t('auctionsPage.statistics.total') }}
