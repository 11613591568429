import { Auction } from '@/staff/domain/exclusive/Auction';
import { AuctionStatus } from '@/staff/domain/exclusive/AuctionStatus';
import { DateUi } from '@/common/primary/date/Date.ui';

export interface AuctionUi {
  id: string;
  name: string;
  startDate: DateUi;
  endDate: DateUi;
  status: AuctionStatus;
  statusTagClass: string;
  anonymous: boolean;
  useLotNumbers: boolean;
}

export const fromAuction = (auction: Auction): AuctionUi => ({
  id: auction.id,
  name: auction.name,
  startDate: new DateUi(auction.startDate),
  endDate: new DateUi(auction.endDate),
  status: auction.status,
  statusTagClass: fromAuctionStatus(auction.status),
  anonymous: auction.anonymous,
  useLotNumbers: auction.useLotNumbers,
});

const fromAuctionStatus = (status: AuctionStatus): string => {
  if (status === AuctionStatus.UPCOMING) {
    return '-info';
  }
  if (status === AuctionStatus.ACTIVE) {
    return '-success';
  }
  return '';
};
