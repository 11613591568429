import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { InjectionKey } from 'vue';
import { Post } from '@/staff/domain/post/Post';
import { PostToCreate } from '@/staff/domain/post/PostToCreate';
import { PostSlug } from '@/staff/domain/post/PostSlug';
import { PostToUpdate } from '@/staff/domain/post/PostToUpdate';
import { DraftDescriptionRequest } from '@/staff/domain/completion/DraftDescriptionRequest';
import { PostDescription } from '@/staff/domain/post/PostDescription';

export const postRepositoryKey: InjectionKey<PostRepository> = Symbol();

export interface PostRepository {
  list(clubSlug: ClubSlug): Promise<Post[]>;
  create(postToCreate: PostToCreate): Promise<Post>;
  getBySlug(clubSlug: ClubSlug, slug: PostSlug): Promise<Post>;
  update(postToUpdate: PostToUpdate): Promise<Post>;
  requestDraftDescription(clubSlug: ClubSlug, request: DraftDescriptionRequest): Promise<PostDescription>;
}
