import { StafferIdentity } from '@/common/domain/auth/StafferIdentity';
import { InjectionKey } from 'vue';
import { AuthenticationJwtTokenRetriever } from '@/common/domain/auth/AuthenticationJwtTokenRetriever';
import { Optional } from '@/common/domain/Optional';
import { Credential } from '@/common/domain/auth/Credential';
import { AuthState } from '@/common/domain/auth/AuthState';

export const authenticationKey: InjectionKey<Authentication> = Symbol();

export interface Authentication extends AuthenticationJwtTokenRetriever {
  loginWithCredential(credential: Credential): Promise<AuthState>;
  googleLogin(): Promise<void>;
  signUp(credential: Credential): Promise<void>;
  confirmEmail(confirmationCode: string, email: string): Promise<void>;
  resendConfirmationCode(email: string): Promise<void>;
  logout(): Promise<void>;
  askResetPassword(email: string): Promise<void>;
  confirmResetPassword(credential: Credential, confirmationCode: string): Promise<void>;
  optionalAuthenticatedStaffer(): Promise<Optional<StafferIdentity>>;
  authenticatedStaffer(): Promise<StafferIdentity>;
  isAuthenticated(): Promise<boolean>;
}
