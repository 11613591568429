import { computed, ComputedRef, defineComponent, inject, onMounted, onUnmounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { MarkdownEditorVue } from '@/common/primary/markdown/editor';
import { hasProgramFormChanged, ProgramFormUi, ProgramTextFormInput } from '@/staff/primary/program/program-form/ProgramForm.ui';
import { globalWindowKey } from '@/common/domain/Window';
import { declareOnBeforeRouteLeave } from '@/common/primary/router/VueHooksDeclarator';
import { renderMarkdown } from '@/common/primary/markdown/MarkdownRenderer';
import { Language } from '@/common/domain/Language';
import { Media } from '@/common/domain/Media';
import { MediaType } from '@/common/domain/MediaType';
import { SingleImageInputVue } from '@/staff/primary/media-input/single-image-input';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { translationFor, TranslationUi } from '@/common/primary/Translation.ui';
import { dragOver, dragStart, drop } from '@/staff/primary/club/club-form/DragDrop';
import { createDraftDescriptionSidebar } from '@/common/primary/sidebar/Sidebars';
import { DraftDescriptionSidebarOptions } from '@/staff/primary/completion/draft-description-sidebar/DraftDescriptionSidebarOptions';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { DraftDescriptionRequest } from '@/staff/domain/completion/DraftDescriptionRequest';
import { sidebarBusKey } from '@/common/domain/sidebar/SidebarBus';
import { programRepositoryKey } from '@/staff/domain/program/ProgramRepository';

const programNameForSlugMatcher = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;

export default defineComponent({
  name: 'ProgramForm',

  components: { SingleImageInputVue, MarkdownEditorVue },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    isUpdating: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    formValue: {
      type: Object as PropType<ProgramFormUi>,
      required: true,
    },
  },

  emits: ['confirm'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const programRepository = inject(programRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const sidebarBus = inject(sidebarBusKey)!;

    const programForm = ref<ProgramFormUi>(JSON.parse(JSON.stringify(props.formValue)));
    const isLoading = ref<boolean>(false);
    const askedCreate = ref<boolean>(false);
    const draftDescription = ref<string>('');
    const isTranslationLoading = ref<boolean>(false);
    const hiddenPreviews = ref<Record<string, boolean>>({});
    const previewVisibleFor = (translation: TranslationUi) => !hiddenPreviews.value[translation.language];

    const formatDescription = (description: string) => renderMarkdown(description) as string;

    const hasFormChanged = () => computed(() => hasProgramFormChanged(programForm.value, props.formValue));
    const hasName = computed(() => programForm.value.nameTranslations[0].value.length > 0);
    const hasDescription = computed(() => programForm.value.descriptionTranslations[0].value.length > 0);

    const mainImage = computed((): Media | undefined => {
      if (programForm.value.imageUrl === '') {
        return undefined;
      }
      return {
        url: programForm.value.imageUrl,
        type: MediaType.IMAGE,
      };
    });

    onMounted(() => {
      globalWindow.onbeforeunload = () => {
        if (hasFormChanged().value && !askedCreate.value) {
          return t('confirmLeaving');
        }
      };
    });

    onUnmounted(() => {
      globalWindow.onbeforeunload = () => {};
    });

    const routeLeaveGuard = () => {
      if (hasFormChanged().value && !askedCreate.value) {
        return globalWindow.confirm(t('confirmLeaving'));
      }
    };

    declareOnBeforeRouteLeave(routeLeaveGuard);

    const confirm = () => {
      if (programForm.value.descriptionTranslations.some(translation => !translation.value?.trim())) {
        globalWindow.alert(t('programForm.blankDescription'));

        return;
      }

      if (!props.isUpdating) {
        askedCreate.value = true;
      }
      emit('confirm', programForm.value);
    };

    const updateDraft = (description: string) => {
      draftDescription.value = description;
      replaceDescriptionWithDraft('fr');
    };

    const requestDraftDescription = (clubSlug: ClubSlug, request: DraftDescriptionRequest) =>
      programRepository.requestDraftDescription(clubSlug, request);

    const openDraftDescriptionSidebar = async () => {
      const modal = createDraftDescriptionSidebar();
      sidebarBus.open<DraftDescriptionSidebarOptions>({
        component: modal,
        isClosable: true,
        title: t('completion.draftDescriptionSidebar.title'),
        options: {
          clubSlug: props.clubSlug,
          name: translationFor(programForm.value.nameTranslations, 'fr'),
          requestDraftDescription,
          onDraftReceived: updateDraft,
        },
      });
    };

    const replaceDescriptionWithDraft = (language: Language) => {
      translationFor(programForm.value.descriptionTranslations, language).value = draftDescription.value;
    };

    const computeSlug = (name: string) => {
      if (props.isUpdating) {
        return;
      }

      programForm.value.slug =
        name &&
        name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .match(programNameForSlugMatcher)!
          .map(x => x.toLowerCase())
          .join('-');
    };

    const onNameFocusOut = (translation: TranslationUi) => {
      if (translation.language !== 'fr') {
        return;
      }

      computeSlug(translation.value);
    };

    const resetTextField = (field: ProgramTextFormInput) => {
      programForm.value[field] = props.formValue[field];
    };

    const resetNameTranslation = (language: Language) => {
      translationFor(programForm.value.nameTranslations, language).value = translationFor(props.formValue.nameTranslations, language).value;
    };

    const resetDescriptionTranslation = (language: Language) => {
      translationFor(programForm.value.descriptionTranslations, language).value = translationFor(
        props.formValue.descriptionTranslations,
        language
      ).value;
    };

    const hasFieldChanged = (field: keyof ProgramFormUi) => programForm.value[field] !== props.formValue[field];

    const hasNameTranslationChanged = (translation: TranslationUi) =>
      translation.value !== translationFor(props.formValue.nameTranslations, translation.language).value;

    const hasDescriptionTranslationChanged = (translation: TranslationUi) =>
      translation.value !== translationFor(props.formValue.descriptionTranslations, translation.language).value;

    const togglePreviewFor = (translation: TranslationUi) => {
      hiddenPreviews.value[translation.language] = !hiddenPreviews.value[translation.language];
    };

    const updateTranslation = (translation: TranslationUi, updatedDescription: string) => {
      translation.value = updatedDescription;
    };

    const updateImage = (media: Media) => (programForm.value.imageUrl = media.url);

    const resetImageUrl = () => {
      programForm.value.imageUrl = props.formValue.imageUrl;
    };

    const translateText = async (targetLanguage: string, translations: TranslationUi[], hasTranslation: ComputedRef<boolean>) => {
      if (hasTranslation.value && !isTranslationLoading.value) {
        isTranslationLoading.value = true;
        const frenchTranslation = translationFor(translations, 'fr');
        const translation = await clubRepository.translateText({
          text: frenchTranslation.value,
          sourceLanguage: 'fr',
          targetLanguage,
          clubSlug: props.clubSlug,
        });
        const index = translations.findIndex(translation => translation.language === targetLanguage);
        translations.splice(index, 1, {
          ...translations[index],
          value: translation,
        });
        isTranslationLoading.value = false;
      }
    };

    const translateName = (targetLanguage: string) => translateText(targetLanguage, programForm.value.nameTranslations, hasName);

    const translateDescription = (targetLanguage: string) =>
      translateText(targetLanguage, programForm.value.descriptionTranslations, hasDescription);

    const onDragStart = (event: DragEvent) => dragStart(event, programForm.value);

    const onDragOver = async (event: DragEvent) => dragOver(event);

    const onDrop = async (event: DragEvent) => {
      drop<ProgramFormUi>(event).ifPresent(value => (programForm.value = value));
    };

    return {
      t,
      mainImage,
      confirm,
      isLoading,
      isTranslationLoading,
      hasDescription,
      hasName,
      translateDescription,
      translateName,
      updateImage,
      programForm,
      resetImageUrl,
      onDragStart,
      onDragOver,
      onDrop,
      onNameFocusOut,
      openDraftDescriptionSidebar,
      resetTextField,
      hasFieldChanged,
      routeLeaveGuard,
      togglePreviewFor,
      updateTranslation,
      previewVisibleFor,
      formatDescription,
      resetNameTranslation,
      hasNameTranslationChanged,
      resetDescriptionTranslation,
      hasDescriptionTranslationChanged,
    };
  },
});
