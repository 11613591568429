import { ProgramRepository } from '@/staff/domain/program/ProgramRepository';
import { Program } from '@/staff/domain/program/Program';
import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { ProgramToCreate } from '@/staff/domain/program/ProgramToCreate';
import { BackendProgram, toProgram } from '@/staff/secondary/program/BackendProgram';
import { toBackendProgramToCreate } from '@/staff/secondary/program/BackendProgramToCreate';
import { ProgramSlug } from '@/staff/domain/program/ProgramSlug';
import { ProgramToUpdate } from '@/staff/domain/program/ProgramToUpdate';
import { toBackendProgramToUpdate } from '@/staff/secondary/program/BackendProgramToUpdate';
import { DraftDescriptionRequest } from '@/staff/domain/completion/DraftDescriptionRequest';
import { toBackendDraftDescriptionRequest } from '@/staff/secondary/completion/BackendDraftDescriptionRequest';
import { ProgramDescription } from '@/staff/domain/program/ProgramDescription';

export class BackendProgramRepository implements ProgramRepository {
  constructor(private backendCaller: BackendCaller) {}

  list(clubSlug: ClubSlug): Promise<Program[]> {
    return this.backendCaller.get<BackendProgram[]>(`api/clubs/${clubSlug}/programs`).then(programs => programs.data.map(toProgram));
  }

  getBySlug(clubSlug: ClubSlug, slug: ProgramSlug): Promise<Program> {
    return this.backendCaller
      .get<BackendProgram>(`api/clubs/${clubSlug}/programs/${slug}`, { params: { 'with-translations': true } })
      .then(programs => toProgram(programs.data));
  }

  update(programToUpdate: ProgramToUpdate): Promise<Program> {
    return this.backendCaller
      .patch<BackendProgram>(
        `api/clubs/${programToUpdate.clubSlug}/programs/${programToUpdate.currentSlug}`,
        toBackendProgramToUpdate(programToUpdate)
      )
      .then(programs => toProgram(programs.data));
  }

  create(programToCreate: ProgramToCreate): Promise<Program> {
    return this.backendCaller
      .post<BackendProgram>(`api/clubs/${programToCreate.clubSlug}/programs`, toBackendProgramToCreate(programToCreate))
      .then(programs => toProgram(programs.data));
  }

  requestDraftDescription(clubSlug: ClubSlug, request: DraftDescriptionRequest): Promise<ProgramDescription> {
    return this.backendCaller
      .post<string>(`api/clubs/${clubSlug}/program-description-draft-requests`, toBackendDraftDescriptionRequest(request))
      .then(({ data }) => data);
  }
}
