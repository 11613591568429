PageVue
  .fp-vertical-spacing.-s-xl
    .fp-vertical-spacing--line
      .fp-vertical-spacing
        .fp-vertical-spacing--line
          .fp-horizontal-spacing.-s-sm.-items-center
            .fp-horizontal-spacing--column
              i.fp-icon.fp-glyph-gavel.-s18
            .fp-horizontal-spacing--column.-expand
              h2.fp-title.-color-shade-700 {{ t('auctionsPage.title') }}
            .fp-horizontal-spacing--column
              FairplayerButtonVue(icon='plus' :text='t(\'auctionsPage.createAuction\')' data-test='create-auction' @click='openCreateAuctionModal')
        .fp-vertical-spacing--line(v-if='!auctions.isLoading()')
          p(v-if='!auctions.value().length' data-test='no-auctions') {{ t('auctionsPage.noAuctions') }}
          .fp-responsive-grid.-s-sm.-cols3.-cols2-up-lg.-cols1-up-sm(v-if='auctions.value().length')
            .fp-responsive-grid--column(v-for='(auction, index) in auctions.value()' :key='auction.id')
              AuctionCardVue(:auction='auction' :is-selected='auction.id === selectedAuctionId' @selected='selectAuction(auction.id)')
    template(v-if='selectedAuctionId')
      .fp-separator.-secondary
      .fp-vertical-spacing--line(data-test='auction-details')
        AuctionDetailsVue(:auction-id='selectedAuctionId' @auction-updated='listAuctions')
