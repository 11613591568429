import { Loader } from '@/common/primary/loader/Loader';
import { computed, defineComponent, inject, onMounted, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { loggerKey } from '@/common/domain/Logger';
import { auctionRepositoryKey } from '@/staff/domain/exclusive/AuctionRepository';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';
import { BidWithExclusiveUi, fromBidWithExclusive } from '@/staff/primary/auction/auction-details/BidWithExclusive.ui';
import { AuctionBidsTableVue } from '@/staff/primary/auction/auction-details/auction-bids-table';
import { AuctionStatisticsVue } from '@/staff/primary/auction/auction-details/auction-statistics';
import { RelativeTimeVue } from '@/common/primary/relative-time';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { updateAuctionModal } from '@/common/primary/modal/Modals';
import { UpdateAuctionModalOptions } from '@/staff/primary/auction/update-auction-modal/UpdateAuctionModalOptions';
import { fromAuction } from '@/staff/primary/exclusive/Auction.ui';
import { Auction } from '@/staff/domain/exclusive/Auction';

export default defineComponent({
  name: 'AuctionDetails',

  props: {
    auctionId: {
      type: String as PropType<string>,
      required: true,
    },
  },

  emits: ['auction-updated'],

  components: { RelativeTimeVue, AuctionBidsTableVue, AuctionStatisticsVue },

  setup(props, { emit }) {
    const { t } = useI18n();

    const auctionRepository = inject(auctionRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const modalBus = inject(modalBusKey)!;

    const bids = ref(Loader.loading<BidWithExclusiveUi[]>());
    const auction = ref(Loader.loading<Auction>());
    const auctionUi = computed(() => fromAuction(auction.value.value()));

    watch(
      () => props.auctionId,
      () => getAuctionDetails()
    );

    const getAuctionDetails = () => {
      getAuction();
      listBids();
    };

    onMounted(() => getAuctionDetails());

    const getAuction = () => {
      auction.value = Loader.loading<Auction>();
      auctionRepository
        .getById(clubRepository.getCurrentClub().slug, props.auctionId)
        .then(auctionRetrieved => auction.value.loaded(auctionRetrieved))
        .catch(error => {
          logger.error('Failed to retrieve auction', error);
        });
    };

    const listBids = () => {
      bids.value = Loader.loading<BidWithExclusiveUi[]>();
      auctionRepository
        .listAuctionBids(clubRepository.getCurrentClub().slug, props.auctionId)
        .then(bidsRetrieved => bids.value.loaded(bidsRetrieved.map(fromBidWithExclusive)))
        .catch(error => {
          bids.value.loaded([]);
          logger.error('Failed to retrieve auction bids', error);
        });
    };

    const openUpdateAuctionModal = () => {
      const modal = updateAuctionModal();
      modalBus.open<UpdateAuctionModalOptions>({
        component: modal,
        options: {
          auction: auction.value.value()!,
          updated: onUpdated,
        },
      });
    };

    const onUpdated = () => {
      getAuction();

      emit('auction-updated');
    };

    return {
      auction,
      auctionUi,
      bids,
      t,
      FairplayerFallbackImageUi,
      openUpdateAuctionModal,
      onUpdated,
    };
  },
});
