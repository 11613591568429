.fp-highlighted-media(v-if='isMediaHighlighted' data-test='layout-highlighted-media' @click='closeHighlightedMedia')
  img.fp-highlighted-media--media(v-if='medias.medias[medias.activeIndex].type === \'IMAGE\'' :class='{"-sliding-right": slidingRight, "-sliding-left": slidingLeft}' :src='medias.medias[medias.activeIndex].url' @click='stopPropagation' data-test='highlighted-image')
  video.fp-highlighted-media--media(v-else controls autoplay @click='stopPropagation' :class='{"-sliding-right": slidingRight, "-sliding-left": slidingLeft}' data-test='highlighted-video')
    source(:src='medias.medias[medias.activeIndex].url' type='video/mp4')
  .fp-highlighted-media--close(data-test='layout-highlighted-media.close')
    button.fp-icon.fp-glyph-close.-s24.-pointer
  .fp-highlighted-media--previous(v-if='medias.activeIndex !== 0' @click='previous' data-test='layout-highlighted-media.previous')
    button.fp-icon.fp-glyph-chevron-left.-s24.-pointer
  .fp-highlighted-media--next(v-if='medias.activeIndex !== medias.medias.length - 1' @click='next' data-test='layout-highlighted-media.next')
    button.fp-icon.fp-glyph-chevron-right.-s24.-pointer
