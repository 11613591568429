import { defineComponent, inject, ref } from 'vue';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { useI18n } from 'vue-i18n';
import { ForgotCodeVue } from '@/common/primary/auth/forgot-password/forgot-code';
import { ResetPasswordVue } from '@/common/primary/auth/forgot-password/reset-password';

export default defineComponent({
  name: 'ForgotPassword',
  components: { ForgotCodeVue, ResetPasswordVue },

  setup() {
    const authentication = inject(authenticationKey)!;
    const { t } = useI18n();
    const forgotCodeStep = ref(true);
    const email = ref('');

    const askResetPassword = async (stafferEmail: string) => {
      await authentication.askResetPassword(stafferEmail);
      forgotCodeStep.value = false;
      email.value = stafferEmail;
    };

    return {
      askResetPassword,
      email,
      forgotCodeStep,
      t,
    };
  },
});
