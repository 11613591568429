import { StafferIdentity } from '@/common/domain/auth/StafferIdentity';
import { Optional } from '@/common/domain/Optional';
import { AlertBus } from '@/common/domain/alert/AlertBus';
import { Logger } from '@/common/domain/Logger';
import { ResourcesConfig } from '@aws-amplify/core';
import { CognitoClient } from '@/common/secondary/auth/CognitoClient';
import { AuthTokens, JwtPayload } from '@aws-amplify/core/src/singleton/Auth/types';
import { Credential } from '@/common/domain/auth/Credential';
import { AuthState } from '@/common/domain/auth/AuthState';
import { Authentication } from '@/common/domain/auth/Authentication';

export const APPLICATION_BASE_URL = window.location.origin;

export const configureCognitoWith = (configureCognitoFn: (resourceConfig: ResourcesConfig) => void, alertBus: AlertBus) => {
  try {
    configureCognitoFn({
      Auth: {
        Cognito: {
          userPoolClientId: import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID,
          userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
          loginWith: {
            oauth: {
              domain: import.meta.env.VITE_COGNITO_DOMAIN,
              scopes: ['email', 'profile', 'openid'],
              redirectSignIn: [`${APPLICATION_BASE_URL}/login/callback`],
              redirectSignOut: [APPLICATION_BASE_URL],
              responseType: 'code',
            },
          },
        },
      },
    });
  } catch (error: any) {
    alertBus.alert('errors.authentication.unknown', 'danger');
    throw new Error(error);
  }
};

export class AuthenticationCognito implements Authentication {
  constructor(
    private cognitoClient: CognitoClient,
    private alertBus: AlertBus,
    private logger: Logger
  ) {}

  async loginWithCredential(credential: Credential): Promise<AuthState> {
    return this.cognitoClient
      .signInWithUserPassword({
        username: credential.email,
        password: credential.password,
        options: {
          authFlowType: 'USER_PASSWORD_AUTH',
        },
      })
      .then(output => ({
        authenticationCompleted: output.isSignedIn,
      }));
  }

  async googleLogin(): Promise<void> {
    return this.cognitoClient.signInWithRedirect({ provider: 'Google' }).catch(error => this.throwError(error));
  }

  async signUp(credential: Credential): Promise<void> {
    return this.cognitoClient.signUp({
      username: credential.email,
      password: credential.password,
    });
  }

  async confirmEmail(confirmationCode: string, email: string): Promise<void> {
    return this.cognitoClient.confirmSignUp({ username: email, confirmationCode });
  }

  async resendConfirmationCode(email: string): Promise<void> {
    return this.cognitoClient.resendSignUpCode({ username: email });
  }

  async logout(): Promise<void> {
    return this.cognitoClient.signOut().catch(error => this.throwError(error));
  }

  async askResetPassword(email: string): Promise<void> {
    return this.cognitoClient.resetPassword({ username: email }).catch(error => this.throwError(error));
  }

  async confirmResetPassword(credential: Credential, confirmationCode: string): Promise<void> {
    return this.cognitoClient.confirmResetPassword({ username: credential.email, confirmationCode, newPassword: credential.password });
  }

  async optionalAuthenticatedStaffer(): Promise<Optional<StafferIdentity>> {
    return (await this.authSession())
      .map(tokens => tokens.idToken?.payload)
      .map(payload => ({
        name: this.extractNameFrom(payload),
        firstName: payload?.given_name as string,
        lastName: payload?.family_name as string,
        username: payload?.sub as string,
      }));
  }

  private extractNameFrom(payload: JwtPayload | undefined) {
    return (payload?.name as string) || (payload?.email as string).split('@')[0];
  }

  async authenticatedStaffer(): Promise<StafferIdentity> {
    const authenticatedStaffer = await this.optionalAuthenticatedStaffer();
    return authenticatedStaffer.orElseThrow(() => new Error('Should be authenticated'));
  }

  async isAuthenticated(): Promise<boolean> {
    return (await this.jwtToken()).isPresent();
  }

  async jwtToken(): Promise<Optional<string>> {
    return (await this.authSession()).map(tokens => tokens.idToken!.toString());
  }

  private async authSession(): Promise<Optional<AuthTokens>> {
    try {
      const authSession = await this.cognitoClient.fetchAuthSession();

      return Optional.ofUndefinable(authSession.tokens);
    } catch (error: any) {
      this.logger.error('Failed to get auth session', error);
      return Optional.empty();
    }
  }

  private throwError(error: any): never {
    this.alertBus.alert('errors.authentication.unknown', 'danger');
    throw new Error(error);
  }
}
