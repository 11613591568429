include ../../../../common/primary/select/select.mixin.pug

form.fp-card.-highlighted(autocomplete='on' @submit.prevent='confirm' @dragover.prevent='onDragOver' @drop.prevent='onDrop' data-test='club.form')
  .fp-vertical-spacing
    .fp-vertical-spacing--line(v-for='presentationTranslation in clubForm.presentationTranslations' :key='presentationTranslation.language')
      .fp-horizontal-spacing.-equivalent
        .fp-horizontal-spacing--column
          .fp-vertical-spacing.-s-sm
            .fp-vertical-spacing--line
              label.fp-field
                .fp-horizontal-spacing.-items-center.-s-sm(:draggable='true' @dragstart='onDragStart' data-test='draggable')
                  .fp-horizontal-spacing--column
                    | {{ t('clubForm.presentation') }}
                  .fp-horizontal-spacing--column
                    img.fp-illustration.-xs(:class='`-flag-${presentationTranslation.language}`' :data-test='`presentation-flag-${presentationTranslation.language}`')
                  .fp-field--actions
                    template(v-if='presentationTranslation.language === "en"')
                      i.fp-icon.fp-glyph-loader.-spin.-s16(v-if='isTranslationLoading')
                      i.fp-icon.fp-glyph-translate(v-else @click='translatePresentation(presentationTranslation.language)' :class='hasPresentation ? "-pointer" : "-color-shade-400"' :title='t(\'clubForm.translate\')' :data-test='`translate-presentation-${presentationTranslation.language}`')
                    i.fp-icon.-pointer(@click='togglePreviewFor(presentationTranslation)' :class='previewVisibleFor(presentationTranslation) ? "fp-glyph-eye-slash" : "fp-glyph-eye"' :data-test='`toggle-preview-${presentationTranslation.language}`' :title='previewVisibleFor(presentationTranslation) ? t("hidePreview") : t("showPreview")')
                    i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetPresentationTranslation(presentationTranslation.language)' :class='hasPresentationTranslationChanged(presentationTranslation) ? "-pointer" : "-color-shade-400"' :data-test='`reset-presentation-${presentationTranslation.language}`' :title='t(\'undo\')')
            .fp-vertical-spacing--line
              MarkdownEditorVue(:text='presentationTranslation' @updated-value='text => updateTranslation(presentationTranslation, text.value)' :touched='isUpdating && hasPresentationTranslationChanged(presentationTranslation)' :data-test='`presentation-${presentationTranslation.language}`' :disabled='isTranslationLoading')
        .fp-horizontal-spacing--column(v-if='previewVisibleFor(presentationTranslation)')
          .fp-vertical-spacing.-s-sm
            .fp-vertical-spacing--line
              .fp-text.-text-semi-bold {{ t('clubForm.preview') }}
            .fp-vertical-spacing--line
              .fp-text.-text-sm(v-html='formatPresentation(presentationTranslation.value)' :data-test='`preview-presentation-${presentationTranslation.language}`')
