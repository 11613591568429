import { Optional } from '@/common/domain/Optional';
import { Club } from '@/staff/domain/club/Club';
import { ClubRepository } from '@/staff/domain/club/ClubRepository';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { ClubSummary } from '@/staff/domain/club/ClubSummary';
import { BackendClubRepository } from '@/staff/secondary/club/BackendClubRepository';
import { UrlParserClubSlugRepository } from '@/staff/secondary/club/UrlParserClubSlugRepository';
import { CoinsGifts } from '@/staff/domain/club/CoinsGifts';
import { NoCurrentClubError } from '@/staff/secondary/club/NoCurrentClubError';
import { Giveaway } from '@/staff/domain/club/giveaway/Giveaway';
import { Coin } from '@/common/domain/token/Coin';
import { FansStatistics } from '@/staff/domain/club/FansStatistics';
import { CoinsHoldings } from '@/staff/domain/club/CoinsHoldings';
import { GiveawayToCreate } from '@/staff/domain/club/giveaway/GiveawayToCreate';
import { GiveawayToUpdate } from '@/staff/domain/club/giveaway/GiveawayToUpdate';
import { GiveawayId } from '@/staff/domain/club/giveaway/GiveawayId';
import { GiveawayClosureToCreate } from '@/staff/domain/club/giveaway/GiveawayClosureToCreate';
import { CoinsGiftToCreate } from '@/staff/domain/club/CoinsGiftToCreate';
import { ClubToUpdate } from '@/staff/domain/club/ClubToUpdate';
import { TranslationRequest } from '@/staff/domain/club/TranslationRequest';

export const SELECTED_CLUB_KEY = 'selected_club_slug';

export class FairplayerClubRepository implements ClubRepository {
  private currentClub: Optional<Club>;
  private clubs: ClubSummary[] | null;
  constructor(
    private backendClubRepository: BackendClubRepository,
    private urlParserClubSlugRepository: UrlParserClubSlugRepository,
    private storage: Storage
  ) {
    this.currentClub = Optional.empty();
    this.clubs = null;
  }

  async list(): Promise<ClubSummary[]> {
    if (this.clubs === null) {
      this.clubs = await this.backendClubRepository.list();
    }

    return this.clubs;
  }

  retrieveClub(): Promise<Club> {
    return this.backendClubRepository.getBySlug(this.getCurrentSlug().orElseThrow(() => new NoCurrentClubError()));
  }

  findSelectedSlug(): Optional<ClubSlug> {
    return Optional.ofUndefinable(this.storage.getItem(SELECTED_CLUB_KEY));
  }

  clearSelectedClub(): void {
    this.storage.removeItem(SELECTED_CLUB_KEY);
  }

  getCurrentSlug(): Optional<ClubSlug> {
    return this.urlParserClubSlugRepository.getCurrentClubSlug();
  }

  setCurrentClub(club: Club): void {
    this.storage.setItem(SELECTED_CLUB_KEY, club.slug);

    this.currentClub = Optional.of(club);
  }

  findCurrentClub(): Optional<Club> {
    return this.currentClub;
  }

  getCurrentClub(): Club {
    return this.findCurrentClub().orElseThrow(() => new NoCurrentClubError());
  }

  update(clubToUpdate: ClubToUpdate): Promise<Club> {
    return this.backendClubRepository.update(clubToUpdate);
  }

  getCoinsGifts(clubSlug: ClubSlug, coin: Coin): Promise<CoinsGifts> {
    return this.backendClubRepository.getCoinsGifts(clubSlug, coin);
  }

  async createCoinsGift(clubSlug: ClubSlug, coinsGift: CoinsGiftToCreate): Promise<void> {
    await this.backendClubRepository.createCoinsGift(clubSlug, coinsGift);
  }

  getCoinsHoldings(clubSlug: ClubSlug, coin: Coin): Promise<CoinsHoldings> {
    return this.backendClubRepository.getCoinsHoldings(clubSlug, coin);
  }

  async createGiveaway(clubSlug: ClubSlug, giveawayToCreate: GiveawayToCreate): Promise<void> {
    await this.backendClubRepository.createGiveaway(clubSlug, giveawayToCreate);
  }

  getGiveaways(clubSlug: ClubSlug): Promise<Giveaway[]> {
    return this.backendClubRepository.getGiveaways(clubSlug);
  }

  getGiveaway(clubSlug: ClubSlug, giveawayId: GiveawayId): Promise<Giveaway> {
    return this.backendClubRepository.getGiveaway(clubSlug, giveawayId);
  }

  updateGiveaway(giveawayToUpdate: GiveawayToUpdate): Promise<Giveaway> {
    return this.backendClubRepository.updateGiveaway(giveawayToUpdate);
  }

  closeGiveaway(closureToCreate: GiveawayClosureToCreate): Promise<void> {
    return this.backendClubRepository.closeGiveaway(closureToCreate);
  }

  getFansStatistics(clubSlug: ClubSlug): Promise<FansStatistics> {
    return this.backendClubRepository.getFansStatistics(clubSlug);
  }

  async translateText(translationRequest: TranslationRequest): Promise<string> {
    return await this.backendClubRepository.translateText(translationRequest);
  }
}
