import { AuctionToUpdate } from '@/staff/domain/exclusive/AuctionToUpdate';
import { BackendTranslation, toBackendTranslation } from '@/common/secondary/BackendTranslation';
import { AuctionName } from '@/staff/domain/exclusive/AuctionName';
import { toBackendArray } from '@/staff/secondary/toBackendArray';

export interface BackendAuctionToUpdate {
  startDate?: string;
  endDate?: string;
  anonymous?: boolean;
  useLotNumbers?: boolean;
  nameTranslations?: BackendTranslation[];
}

export const toBackendAuctionToUpdate = (auction: AuctionToUpdate): BackendAuctionToUpdate => ({
  startDate: auction.startDate.map(date => date.toISOString()).orElseUndefined(),
  endDate: auction.endDate.map(date => date.toISOString()).orElseUndefined(),
  anonymous: auction.anonymous.orElseUndefined(),
  useLotNumbers: auction.useLotNumbers.orElseUndefined(),
  nameTranslations: toBackendArray(auction.nameTranslations, toBackendTranslation<AuctionName>),
});
