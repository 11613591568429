import { Post } from '@/staff/domain/post/Post';
import { Optional } from '@/common/domain/Optional';
import { BackendMedia, fromBackendMedia } from '@/staff/secondary/BackendMedia';
import { BackendTranslation, fromBackendTranslation } from '@/common/secondary/BackendTranslation';
import { PostName } from '@/staff/domain/post/PostName';
import { PostDescription } from '@/staff/domain/post/PostDescription';

export interface BackendPost {
  id: string;
  slug: string;
  imageUrl: string;
  medias: BackendMedia[];
  name: string;
  description: string;
  date: string;
  nameTranslations?: BackendTranslation[];
  descriptionTranslations?: BackendTranslation[];
  programSlug?: string;
}

export const toPost = (backendPost: BackendPost): Post => ({
  id: backendPost.id,
  slug: backendPost.slug,
  imageUrl: backendPost.imageUrl,
  medias: backendPost.medias.map(fromBackendMedia),
  name: backendPost.name,
  description: backendPost.description,
  date: new Date(backendPost.date),
  nameTranslations: backendPost.nameTranslations?.map(fromBackendTranslation<PostName>),
  descriptionTranslations: backendPost.descriptionTranslations?.map(fromBackendTranslation<PostDescription>),
  programSlug: Optional.ofUndefinable(backendPost.programSlug),
});
