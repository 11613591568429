import { Media } from '@/common/domain/Media';
import { MediaType } from '@/common/domain/MediaType';

export interface BackendMedia {
  url: string;
  type: MediaType;
}

export const fromBackendMedia = (backendMedia: BackendMedia): Media => ({
  url: backendMedia.url,
  type: backendMedia.type,
});

export const toBackendMedia = (media: Media): BackendMedia => ({
  url: media.url,
  type: media.type,
});
