import { Optional } from '@/common/domain/Optional';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { PostSlug } from '@/staff/domain/post/PostSlug';
import { Url } from '@/common/domain/Url';
import { Media } from '@/common/domain/Media';
import { PostName } from '@/staff/domain/post/PostName';
import { PostDescription } from '@/staff/domain/post/PostDescription';
import { Translation } from '@/common/domain/Translation';

export interface PostToUpdate {
  clubSlug: ClubSlug;
  currentSlug: PostSlug;
  slug: Optional<PostSlug>;
  imageUrl: Optional<Url>;
  date: Optional<Date>;
  medias: Optional<Media[]>;
  nameTranslations: Optional<Translation<PostName>[]>;
  descriptionTranslations: Optional<Translation<PostDescription>[]>;
}

type UpdatablePostField = keyof Omit<PostToUpdate, 'clubSlug' | 'currentSlug'>;

export const isPostToUpdateEmpty = (postToUpdate: PostToUpdate) =>
  Object.keys(postToUpdate)
    .filter(key => key !== 'clubSlug' && key !== 'currentSlug')
    .map(key => key as UpdatablePostField)
    .map(key => postToUpdate[key])
    .every(field => field.isEmpty());
