import { MediaType } from '@/common/domain/MediaType';
import { Url } from '@/common/domain/Url';
import { Media } from '@/common/domain/Media';

export interface MediaUi {
  url: Url;
  type: MediaType;
}

export const toMediaUi = (media: Media): MediaUi => ({
  url: media.url,
  type: media.type,
});

export const fromMediaUi = (mediaUi: MediaUi): Media => ({
  url: mediaUi.url,
  type: mediaUi.type,
});
