import { Loader } from '@/common/primary/loader/Loader';
import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { RelativeTimeVue } from '@/common/primary/relative-time';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { BidWithExclusiveUi } from '@/staff/primary/auction/auction-details/BidWithExclusive.ui';

export default defineComponent({
  name: 'AuctionBidsTable',

  components: { RelativeTimeVue, FairplayerImageVue },

  props: {
    bids: {
      type: Object as PropType<Loader<BidWithExclusiveUi[]>>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const hasBids = computed(() => !props.bids.isLoading() && props.bids.value().length > 0);

    return {
      hasBids,
      t,
      FairplayerFallbackImageUi,
    };
  },
});
