import { ClubSummary } from '@/staff/domain/club/ClubSummary';

export interface ClubSummaryUi {
  name: string;
  platformName: string;
  slug: string;
  logoUrl: string;
  bannerUrl: string;
  webappUrl: string;
}

export const fromClubSummary = (clubSummary: ClubSummary): ClubSummaryUi => ({
  name: clubSummary.name,
  platformName: clubSummary.platformName,
  slug: clubSummary.slug,
  logoUrl: clubSummary.logoUrl,
  bannerUrl: clubSummary.bannerUrl,
  webappUrl: clubSummary.webappUrl,
});
