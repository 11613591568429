import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { loggerKey } from '@/common/domain/Logger';
import { NotFoundVue } from '@/common/primary/not-found';
import { clubNotFoundUi } from '@/common/primary/not-found/NotFound.ui';
import {
  coinsItem,
  exclusivesItem,
  giveawaysItem,
  fansItem,
  overviewItem,
  postsItem,
  programsItem,
  auctionsItem,
} from '@/staff/primary/club-layout/MenuItem.ui';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { ModalVue } from '@/common/primary/modal';
import { ToastsListVue } from '@/staff/primary/toasts/toasts-list';
import { stafferRepositoryKey } from '@/staff/domain/staffer/StafferRepository';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { Loader } from '@/common/primary/loader/Loader';
import { ClubUi, fromClub } from '@/staff/primary/club/Club.ui';
import { createDevEnvironmentAnnouncementUi } from '@/common/primary/announcement/Announcement.ui';
import { AnnouncementVue } from '@/common/primary/announcement';
import { RouterTab } from '@/router/RouterTab';
import { HighlightedMediaVue } from '@/staff/primary/club-layout/highlighted-media';

export default defineComponent({
  name: 'ClubLayout',

  components: { AnnouncementVue, NotFoundVue, ToastsListVue, ModalVue, HighlightedMediaVue },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const route = useRoute();

    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const stafferRepository = inject(stafferRepositoryKey)!;

    const authorizations = ref(Loader.loading<string[]>());
    const menuItems = ref([overviewItem]);
    const clubUi = ref(Loader.loading<ClubUi | null>());
    const devEnvironmentAnnouncementUi = createDevEnvironmentAnnouncementUi();
    const displayDevEnvironmentAnnouncement = ref(import.meta.env.VITE_ENV_SLUG === 'dev');

    const currentRouterTabName = computed(() => route.meta.tab);
    const clubLoaded = computed(() => !clubUi.value.isLoading() && clubUi.value.value() !== null);
    const clubNotFound = computed(() => !clubUi.value.isLoading() && clubUi.value.value() === null);

    const showFooter = computed(() => !authorizations.value.isLoading() && authorizations.value.value().includes('clubs-update'));

    onMounted(async () => {
      await retrieveCurrentClub();
      await retrieveAuthorizations();
    });

    const retrieveCurrentClub = async () => {
      await clubRepository
        .retrieveClub()
        .then(club => {
          clubRepository.setCurrentClub(club);
          clubUi.value.loaded(fromClub(club));
        })
        .catch(() => {
          logger.error('Failed to retrieve club');
          clubUi.value.loaded(null);
        });
    };

    const retrieveAuthorizations = async () => {
      const stafferIdentity = await authentication.authenticatedStaffer();
      stafferRepository
        .getAuthorizationsFor(stafferIdentity.username, props.clubSlug)
        .then(retrievedAuthorizations => {
          authorizations.value.loaded(retrievedAuthorizations);
          addAuthorizedItems();
        })
        .catch(() => {
          authorizations.value.loaded([]);
        });
    };

    const addAuthorizedItems = () => {
      const club = clubUi.value.value()!;
      const stafferAuthorizations = authorizations.value.value();

      if (club.coinsEnabled && stafferAuthorizations.includes('clubs-read-giveaways')) {
        menuItems.value.push(giveawaysItem);
      }
      if (
        club.coinsEnabled &&
        stafferAuthorizations.includes('clubs-read-coins-gifts') &&
        stafferAuthorizations.includes('clubs-read-coins-holdings')
      ) {
        menuItems.value.push(coinsItem);
      }
      if (club.isFoundation && stafferAuthorizations.includes('clubs-read-bids')) {
        menuItems.value.push(auctionsItem);
      }
      if (stafferAuthorizations.includes('clubs-update-exclusive')) {
        menuItems.value.push(exclusivesItem);
      }
      if (club.isFoundation && stafferAuthorizations.includes('clubs-update-program')) {
        menuItems.value.push(programsItem);
      }
      if (club.isFoundation && stafferAuthorizations.includes('clubs-update-post')) {
        menuItems.value.push(postsItem);
      }
      if (club.referralNeeded && stafferAuthorizations.includes('clubs-read-fans')) {
        menuItems.value.push(fansItem);
      }
    };

    const hideDevAnnouncement = () => {
      displayDevEnvironmentAnnouncement.value = false;
    };

    return {
      authorizations,
      menuItems,
      clubNotFoundUi,
      clubLoaded,
      clubUi,
      currentRouterTabName,
      clubNotFound,
      devEnvironmentAnnouncementUi,
      displayDevEnvironmentAnnouncement,
      hideDevAnnouncement,
      showFooter,
      RouterTab,
      t,
    };
  },
});
