import { BackendMember, toMember } from '@/staff/secondary/fan/BackendMember';
import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { BackendExclusiveSummary, toExclusiveSummary } from '@/staff/secondary/exclusive/BackendExclusiveSummary';
import { BidWithExclusive } from '@/staff/domain/exclusive/BidWithExclusive';

export interface BackendBidWithExclusive {
  id: string;
  bidder: BackendMember;
  price: BackendPrice;
  date: string;
  exclusive: BackendExclusiveSummary;
}

export const toBidWithExclusive = (backendBid: BackendBidWithExclusive): BidWithExclusive => ({
  id: backendBid.id,
  bidder: toMember(backendBid.bidder),
  price: toTokens(backendBid.price),
  date: new Date(backendBid.date),
  exclusive: toExclusiveSummary(backendBid.exclusive),
});
