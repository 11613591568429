PageVue
  .fp-vertical-spacing
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-s-sm.-items-center
        .fp-horizontal-spacing--column
          button.fp-icon.fp-glyph-chevron-left.-s16.-pointer(data-test='update-club.back' @click='onGoBack')
        .fp-horizontal-spacing--column
          h2.fp-title {{ t('clubForm.updateTitle') }}
    .fp-vertical-spacing--line
      ClubFormVue#update-club-form(:slug='clubSlug' :is-updating='true' :form-value='clubForm' @confirm='onConfirmUpdate')
  template(v-slot:footer)
    .fp-horizontal-spacing
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'goBack\')' icon='chevron-left' secondary data-test='update-club.footer.back' @click='onGoBack')
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'clubForm.updateClub\')' type='submit' form='update-club-form' icon='edit' data-test='update-club.submit')
