include ../../icon/icon.mixin.pug

.fp-staff-layout
  .fp-staff-layout--modal
    .fp-modal
      .fp-vertical-spacing(data-test='two-factor-authentication')
        .fp-vertical-spacing--line.-justify-center
          img.fp-illustration.-fairplayer-logo
        .fp-vertical-spacing--line.-align-center
          h1.fp-title {{ t('loginRedirect.title') }}
        .fp-vertical-spacing--line.-align-center
          span.fp-text.-paragraph {{ t('loginRedirect.description') }}
        form.fp-vertical-spacing--line.-align-center(@submit.prevent='sendTwoFactorAuthentication' data-test='form')
          label.fp-field
            | {{ t('loginRedirect.code') }}
            input.fp-input-text(type='text' v-model='twoFactorAuthenticationCode' data-test='two-factor-authentication-code')
        .fp-vertical-spacing--line.-align-center(v-if='invalidTwoFactorAuthentication')
          span.fp-text.-color-danger(data-test='invalid-two-factor-authentication') {{ t('loginRedirect.invalidTwoFactorAuthenticationCode') }}
        .fp-vertical-spacing--line
          .fp-vertical-spacing.-s-xs
            .fp-vertical-spacing--line.-align-center
              span.fp-text.-text-sm {{ t('loginRedirect.notReceived') }}
            .fp-vertical-spacing--line.-align-center
              span.fp-text.-text-sm(@click='newTwoFactorAuthentication' data-test='new-two-factor-authentication')
                a.fp-link.-secondary {{ t('loginRedirect.resend') }}
            .fp-vertical-spacing--line.-justify-center(v-if='isAskingNewTwoFactorAuthentication')
              +fp-icon('loader', {color: 'shade-400', size: '18', spin: true})
        .fp-vertical-spacing--line.-justify-center
          FairplayerButtonVue(icon='check' :text='t(\'loginRedirect.validate\')' type='submit' @click='sendTwoFactorAuthentication' :loading='isSendingTwoFactorAuthentication' :disabled='twoFactorAuthenticationCode.length === 0' data-test='send-two-factor-authentication')
