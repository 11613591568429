import { AuctionToCreate } from '@/staff/domain/exclusive/AuctionToCreate';
import { BackendTranslation, toBackendTranslation } from '@/common/secondary/BackendTranslation';

export interface BackendAuctionToCreate {
  startDate: string;
  endDate: string;
  anonymous: boolean;
  useLotNumbers: boolean;
  nameTranslations: BackendTranslation[];
}

export const toBackendAuctionToCreate = (auction: AuctionToCreate): BackendAuctionToCreate => ({
  startDate: auction.startDate.toISOString(),
  endDate: auction.endDate.toISOString(),
  anonymous: auction.anonymous,
  useLotNumbers: auction.useLotNumbers,
  nameTranslations: auction.nameTranslations.map(toBackendTranslation),
});
