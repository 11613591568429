import { Language } from '@/common/domain/Language';
import { TranslationRequest } from '@/staff/domain/club/TranslationRequest';

export interface BackendTranslationRequest {
  text: string;
  sourceLanguage: Language;
  targetLanguage: Language;
}

export const toBackendTranslationRequest = (request: TranslationRequest): BackendTranslationRequest => ({
  text: request.text,
  sourceLanguage: request.sourceLanguage,
  targetLanguage: request.targetLanguage,
});
