import { Optional } from '@/common/domain/Optional';
import { BackendTranslation, toBackendTranslation } from '@/common/secondary/BackendTranslation';
import { ClubToUpdate } from '@/staff/domain/club/ClubToUpdate';
import { TranslatableField } from '@/common/domain/TranslatableField';
import { Translation } from '@/common/domain/Translation';

export interface BackendClubToUpdate {
  presentationTranslations?: BackendTranslation[];
}

export const toBackendClubToUpdate = (clubToUpdate: ClubToUpdate): BackendClubToUpdate => ({
  presentationTranslations: toBackendTranslations(clubToUpdate.presentationTranslations),
});

const toBackendTranslations = <T extends TranslatableField>(translations: Optional<Translation<T>[]>): BackendTranslation[] | undefined => {
  if (translations.isEmpty()) {
    return undefined;
  }

  return translations.map(actualValues => actualValues.map(toBackendTranslation<T>)).orElseUndefined();
};
