import { inject, onMounted } from 'vue';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { useI18n } from 'vue-i18n';
import router from '@/router/router';
import { loginContextRepositoryKey } from '@/common/domain/login/LoginContextRepository';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { stafferRepositoryKey } from '@/staff/domain/staffer/StafferRepository';
import { loggerKey } from '@/common/domain/Logger';
import { LoginFormVue } from '@/common/primary/auth/login-form';

export default {
  name: 'LoginRedirectCallback',

  components: { LoginFormVue },

  setup() {
    const { t } = useI18n();
    const authentication = inject(authenticationKey)!;
    const loginContextRepository = inject(loginContextRepositoryKey)!;
    const stafferRepository = inject(stafferRepositoryKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;
    const logger = inject(loggerKey)!;

    onMounted(async () => {
      const staffer = await authentication.authenticatedStaffer();
      return stafferRepository
        .register(staffer.username)
        .then(() => redirect())
        .catch(error => {
          logger.error('Failed to login', error);
        });
    });

    const redirect = async (): Promise<void> => {
      const { redirectUrl } = loginContextRepository.retrieveLoginContext();
      if (redirectUrl) {
        return pageRedirecter.navigateTo(redirectUrl);
      }

      await router.push({ name: 'home' });
    };

    return {
      t,
    };
  },
};
