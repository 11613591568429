import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { Exclusive } from '@/staff/domain/exclusive/Exclusive';
import { ExclusiveCategory } from '@/staff/domain/exclusive/ExclusiveCategory';
import { BackendMedia, fromBackendMedia } from '@/staff/secondary/BackendMedia';
import { ExclusiveName } from '@/staff/domain/exclusive/ExclusiveName';
import { ExclusiveDescription } from '@/staff/domain/exclusive/ExclusiveDescription';
import { BackendTranslation, fromBackendTranslation } from '@/common/secondary/BackendTranslation';
import { Optional } from '@/common/domain/Optional';
import { BackendAuctionLot, toAuctionLot } from '@/staff/secondary/exclusive/BackendAuctionLot';

export interface BackendExclusive {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  medias: BackendMedia[];
  price: BackendPrice;
  visible: boolean;
  stock: number;
  category: ExclusiveCategory;
  slug: string;
  likes: number;
  auctionLot?: BackendAuctionLot;
  nameTranslations?: BackendTranslation[];
  descriptionTranslations?: BackendTranslation[];
}

export const toExclusive = (backendExclusive: BackendExclusive): Exclusive => ({
  id: backendExclusive.id,
  name: backendExclusive.name,
  description: backendExclusive.description.replaceAll('\\n', '\n'),
  imageUrl: backendExclusive.imageUrl,
  medias: backendExclusive.medias.map(fromBackendMedia),
  pricing: toTokens(backendExclusive.price),
  visible: backendExclusive.visible,
  stock: backendExclusive.stock,
  category: backendExclusive.category,
  slug: backendExclusive.slug,
  likes: backendExclusive.likes,
  auctionLot: Optional.ofUndefinable(backendExclusive.auctionLot).map(toAuctionLot),
  nameTranslations: backendExclusive.nameTranslations?.map(fromBackendTranslation<ExclusiveName>),
  descriptionTranslations: backendExclusive.descriptionTranslations?.map(fromBackendTranslation<ExclusiveDescription>),
});

export const sortExclusiveByPriceDescThenNameAsc = (exclusive1: Exclusive, exclusive2: Exclusive) => {
  if (exclusive2.pricing.totalCost.value === exclusive1.pricing.totalCost.value) {
    return exclusive1.name.localeCompare(exclusive2.name);
  }

  return exclusive2.pricing.totalCost.value - exclusive1.pricing.totalCost.value;
};
