import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { Exclusive } from '@/staff/domain/exclusive/Exclusive';
import { ExclusiveDescription } from '@/staff/domain/exclusive/ExclusiveDescription';
import { ExclusiveToCreate } from '@/staff/domain/exclusive/ExclusiveToCreate';
import { Exclusives } from '@/staff/domain/exclusive/Exclusives';
import { ExclusiveSlug } from '@/staff/domain/exclusive/ExclusiveSlug';
import { ExclusiveToUpdate } from '@/staff/domain/exclusive/ExclusiveToUpdate';
import { InjectionKey } from 'vue';
import { Coin } from '@/common/domain/token/Coin';

import { DraftDescriptionRequest } from '@/staff/domain/completion/DraftDescriptionRequest';

export const exclusiveRepositoryKey: InjectionKey<ExclusiveRepository> = Symbol();

export interface ExclusiveRepository {
  getByClub(clubSlug: ClubSlug, coin: Coin): Promise<Exclusives>;
  getBySlug(clubSlug: ClubSlug, slug: ExclusiveSlug): Promise<Exclusive>;
  update(exclusiveToUpdate: ExclusiveToUpdate): Promise<Exclusive>;
  create(exclusiveToCreate: ExclusiveToCreate): Promise<Exclusive>;
  requestDraftDescription(clubSlug: ClubSlug, request: DraftDescriptionRequest): Promise<ExclusiveDescription>;
}
