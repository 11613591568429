import { useI18n } from 'vue-i18n';
import { PageVue } from '@/staff/primary/club-layout/page';
import { defineComponent, inject, onMounted, PropType, ref, watch } from 'vue';
import { auctionRepositoryKey } from '@/staff/domain/exclusive/AuctionRepository';
import { Loader } from '@/common/primary/loader/Loader';
import { AuctionCardVue } from '@/staff/primary/auction/auctions-page/auction-card';
import { AuctionDetailsVue } from '@/staff/primary/auction/auction-details';
import { FairplayerButtonVue } from '@/common/primary/button';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { createAuctionModal } from '@/common/primary/modal/Modals';
import { useRoute, useRouter } from 'vue-router';
import { CreateAuctionModalOptions } from '@/staff/primary/auction/create-auction-modal/CreateAuctionModalOptions';
import { Auction } from '@/staff/domain/exclusive/Auction';

export default defineComponent({
  name: 'AuctionsPage',

  components: {
    PageVue,
    AuctionCardVue,
    AuctionDetailsVue,
    FairplayerButtonVue,
  },

  props: {
    clubSlug: {
      type: String as PropType<string>,
      required: true,
    },
    auctionId: {
      type: String as PropType<string>,
      required: false,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const auctionRepository = inject(auctionRepositoryKey)!;
    const modalBus = inject(modalBusKey)!;

    const auctions = ref(Loader.loading<Auction[]>());
    const selectedAuctionId = ref();

    const listAuctions = () =>
      auctionRepository.listByClub(props.clubSlug).then(auctionsRetrieved => auctions.value.loaded(auctionsRetrieved));

    const openCreateAuctionModal = () => {
      const modal = createAuctionModal();
      modalBus.open<CreateAuctionModalOptions>({
        component: modal,
        options: {
          created: listAuctions,
        },
      });
    };

    const selectAuction = (auctionId: string) => {
      selectedAuctionId.value = auctionId;
      router.push({ name: 'auctionDetails', params: { auctionId } });
    };

    const selectFirstAuctionIfExists = () => {
      if (auctions.value.value().length > 0) {
        selectedAuctionId.value = auctions.value.value()[0].id;
      }
    };

    onMounted(() =>
      listAuctions().then(() => {
        if (props.auctionId && auctions.value.value().some(a => a.id === props.auctionId)) {
          selectedAuctionId.value = props.auctionId;
        } else {
          selectFirstAuctionIfExists();
          router.replace({
            name: 'auctions',
          });
        }
      })
    );

    watch(
      () => [route.name],
      () => {
        if (route.name === 'auctions') {
          selectFirstAuctionIfExists();
        }
      }
    );

    return {
      selectedAuctionId,
      selectAuction,
      openCreateAuctionModal,
      listAuctions,
      auctions,
      t,
    };
  },
});
