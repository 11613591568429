import { Optional } from '@/common/domain/Optional';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { ProgramSlug } from '@/staff/domain/program/ProgramSlug';
import { Url } from '@/common/domain/Url';
import { Translation } from '@/common/domain/Translation';
import { ProgramName } from '@/staff/domain/program/ProgramName';
import { ProgramDescription } from '@/staff/domain/program/ProgramDescription';

export interface ProgramToUpdate {
  clubSlug: ClubSlug;
  currentSlug: ProgramSlug;
  slug: Optional<ProgramSlug>;
  imageUrl: Optional<Url>;
  nameTranslations: Optional<Translation<ProgramName>[]>;
  descriptionTranslations: Optional<Translation<ProgramDescription>[]>;
}

type UpdatableProgramField = keyof Omit<ProgramToUpdate, 'clubSlug' | 'currentSlug'>;

export const isProgramToUpdateEmpty = (programToUpdate: ProgramToUpdate) =>
  Object.keys(programToUpdate)
    .filter(key => key !== 'clubSlug' && key !== 'currentSlug')
    .map(key => key as UpdatableProgramField)
    .map(key => programToUpdate[key])
    .every(field => field.isEmpty());
