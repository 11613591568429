import { Optional } from '@/common/domain/Optional';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { Translation } from '@/common/domain/Translation';
import { ClubPresentation } from '@/staff/domain/club/ClubPresentation';

export interface ClubToUpdate {
  slug: ClubSlug;
  presentationTranslations: Optional<Translation<ClubPresentation>[]>;
}

type UpdatableExclusiveField = keyof Omit<ClubToUpdate, 'slug'>;

export const isClubToUpdateEmpty = (clubToUpdate: ClubToUpdate) =>
  Object.keys(clubToUpdate)
    .filter(key => key !== 'slug')
    .map(key => key as UpdatableExclusiveField)
    .map(key => clubToUpdate[key])
    .every(field => field.isEmpty());
