include ../../../club/loading-table.mixin.pug

.fp-vertical-spacing.-s-sm(data-test='giveaway-results-statistics')
  .fp-vertical-spacing--line
    h3.fp-title {{ t('giveawayResults.statistics.title') }}
  .fp-vertical-spacing--line
    template(v-if='questionsStatistics.isLoading()')
      +loading-table
    .fp-responsive-grid.-cols2.-cols1-up-sm(v-else)
      .fp-responsive-grid--column
        .fp-card.fp-vertical-spacing(data-test='giveaway-results-statistics.questions' style='max-height: 50vh; overflow-y: auto')
          .fp-checkable-element(v-for='(question, index) in giveawayQuestions' :key='index')
            .fp-checkable-element--checkbox
              input.fp-radio(
                type='radio'
                :id='question.id'
                name='questions'
                :checked='index === selectedQuestion'
                @click='onQuestionClick(index)'
                :data-test='`question-${index}`')
            .fp-checkable-element--label
              label(:for='question.id') {{ question.title }}
      .fp-responsive-grid--column(style='max-height: 50vh; overflow-y: auto')
        GiveawayResultsChartVue(:selected-question='selectedQuestion' :questions='giveawayQuestions' :questions-statistics='questionsStatistics.value()')
