import { CreateExclusiveVue } from '@/staff/primary/exclusive/create-exclusive';
import { createRouter, createWebHistory } from 'vue-router';
import { LoginRedirectCallbackVue } from '@/login/redirect-callback';
import { UpdateExclusiveVue } from '@/staff/primary/exclusive/update-exclusive';
import { ClubLayoutVue } from '@/staff/primary/club-layout';
import { ClubOverviewVue } from '@/staff/primary/club-overview';
import { ClubsPageVue } from '@/staff/primary/clubs-page';
import { ClubSelectionVue } from '@/staff/primary/club-selection';
import { CreateGiveawayVue } from '@/staff/primary/giveaway/create-giveaway';
import { UpdateGiveawayVue } from '@/staff/primary/giveaway/update-giveaway';
import { NotFoundVue } from '@/common/primary/not-found';
import { GiveawayResultsVue } from '@/staff/primary/giveaway/giveaway-results';
import { RouterTab } from '@/router/RouterTab';
import { GiveawaysPageVue } from '@/staff/primary/giveaway/giveaways-page';
import { ExclusivesPageVue } from '@/staff/primary/exclusive/exclusives-page';
import { CoinsPageVue } from '@/staff/primary/club/coins-page';
import { ProgramsPageVue } from '@/staff/primary/program/programs-page';
import { CreateProgramVue } from '@/staff/primary/program/create-program';
import { UpdateProgramVue } from '@/staff/primary/program/update-program';
import { PostsPageVue } from '@/staff/primary/post/posts-page';
import { CreatePostVue } from '@/staff/primary/post/create-post';
import { UpdatePostVue } from '@/staff/primary/post/update-post';
import { FansPageVue } from '@/staff/primary/fan/fans-page';
import { AuctionsPageVue } from '@/staff/primary/auction/auctions-page';
import { UpdateClubVue } from '@/staff/primary/club/update-club';
import { AuctionDetailsVue } from '@/staff/primary/auction/auction-details';
import { LoginPageVue } from '@/common/primary/auth/login-page';
import { SignupVue } from '@/common/primary/auth/signup';
import { ForgotPasswordVue } from '@/common/primary/auth/forgot-password';

export const routes = [
  {
    path: '/',
    name: 'home',
    component: ClubSelectionVue,
  },
  {
    path: '/clubs',
    name: 'clubsPage',
    component: ClubsPageVue,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPageVue,
    meta: {
      accessibleUnauthenticated: true,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupVue,
    meta: {
      accessibleUnauthenticated: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPasswordVue,
    meta: {
      accessibleUnauthenticated: true,
    },
  },
  {
    path: '/login/callback',
    name: 'loginRedirectCallback',
    component: LoginRedirectCallbackVue,
    meta: {
      accessibleUnauthenticated: true,
    },
  },
  {
    path: '/clubs/:clubSlug',
    component: ClubLayoutVue,
    props: true,
    children: [
      {
        path: '',
        name: 'clubOverview',
        component: ClubOverviewVue,
        meta: {
          tab: RouterTab.OVERVIEW,
        },
      },
      {
        path: 'edit',
        name: 'updateClub',
        component: UpdateClubVue,
        props: true,
        meta: {
          tab: RouterTab.CLUB,
        },
      },
      {
        path: 'exclusives',
        name: 'exclusives',
        component: ExclusivesPageVue,
        props: true,
        meta: {
          tab: RouterTab.EXCLUSIVES,
        },
      },
      {
        path: 'auctions',
        name: 'auctions',
        component: AuctionsPageVue,
        props: true,
        meta: {
          tab: RouterTab.AUCTIONS,
        },
        children: [
          {
            path: ':auctionId',
            name: 'auctionDetails',
            component: AuctionDetailsVue,
          },
        ],
      },
      {
        path: 'exclusives/new',
        name: 'createExclusive',
        component: CreateExclusiveVue,
        props: true,
        meta: {
          tab: RouterTab.EXCLUSIVES,
        },
      },
      {
        path: 'exclusives/:exclusiveSlug',
        name: 'updateExclusive',
        component: UpdateExclusiveVue,
        props: true,
        meta: {
          tab: RouterTab.EXCLUSIVES,
        },
      },
      {
        path: 'giveaways',
        name: 'giveaways',
        component: GiveawaysPageVue,
        props: true,
        meta: {
          tab: RouterTab.GIVEAWAYS,
        },
      },
      {
        path: 'giveaways/new',
        name: 'createGiveaway',
        component: CreateGiveawayVue,
        props: true,
        meta: {
          tab: RouterTab.GIVEAWAYS,
        },
      },
      {
        path: 'giveaways/:giveawayId',
        name: 'updateGiveaway',
        component: UpdateGiveawayVue,
        props: true,
        meta: {
          tab: RouterTab.GIVEAWAYS,
        },
      },
      {
        path: 'giveaways/:giveawayId/results',
        name: 'giveawayResults',
        component: GiveawayResultsVue,
        props: true,
        meta: {
          tab: RouterTab.GIVEAWAYS,
        },
      },
      {
        path: 'coins',
        name: 'coins',
        component: CoinsPageVue,
        props: true,
        meta: {
          tab: RouterTab.COINS,
        },
      },
      {
        path: 'programs',
        name: 'programs',
        component: ProgramsPageVue,
        props: true,
        meta: {
          tab: RouterTab.PROGRAMS,
        },
      },
      {
        path: 'programs/new',
        name: 'createProgram',
        component: CreateProgramVue,
        props: true,
        meta: {
          tab: RouterTab.PROGRAMS,
        },
      },
      {
        path: 'programs/:programSlug',
        name: 'updateProgram',
        component: UpdateProgramVue,
        props: true,
        meta: {
          tab: RouterTab.PROGRAMS,
        },
      },
      {
        path: 'posts',
        name: 'posts',
        component: PostsPageVue,
        props: true,
        meta: {
          tab: RouterTab.POSTS,
        },
      },
      {
        path: 'posts/new',
        name: 'createPost',
        component: CreatePostVue,
        props: true,
        meta: {
          tab: RouterTab.POSTS,
        },
      },
      {
        path: 'posts/:postSlug',
        name: 'updatePost',
        component: UpdatePostVue,
        props: true,
        meta: {
          tab: RouterTab.POSTS,
        },
      },
      {
        path: 'fans',
        name: 'fans',
        component: FansPageVue,
        props: true,
        meta: {
          tab: RouterTab.FANS,
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFoundVue,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
