include ../../../../common/primary/select/select.mixin.pug

form(autocomplete='on' @submit.prevent='confirm' @drop.prevent='onDrop' @dragover.prevent='onDragOver' data-test='giveaway.form')
  .fp-vertical-spacing
    .fp-vertical-spacing--line
      .fp-card.-highlighted
        .fp-vertical-spacing
          .fp-vertical-spacing--line
            .fp-vertical-spacing.-s-xs
              .fp-vertical-spacing--line
                .fp-responsive-grid.-cols4.-cols2-up-md
                  .fp-responsive-grid--column
                    label.fp-field
                      span(:draggable='true' @dragstart='onDragStart' data-test='draggable') {{ t('giveawayForm.startDate') }}
                      input.fp-input-text(v-model='giveawayForm.startDate.date' type='date' :disabled='limitedUpdate' required data-test='startDate.date')
                  .fp-responsive-grid--column
                    label.fp-field
                      | {{ t('giveawayForm.startTime') }}
                      input.fp-input-text(v-model='giveawayForm.startDate.time' type='time' :disabled='limitedUpdate' required data-test='startDate.time')
                  .fp-responsive-grid--column
                    label.fp-field
                      | {{ t('giveawayForm.endDate') }}
                      input.fp-input-text(v-model='giveawayForm.endDate.date' type='date' required data-test='endDate.date')
                  .fp-responsive-grid--column
                    label.fp-field
                      | {{ t('giveawayForm.endTime') }}
                      input.fp-input-text(v-model='giveawayForm.endDate.time' type='time' required data-test='endDate.time')
              .fp-vertical-spacing--line.-align-right(v-if='!isValidStartDate' data-test='invalid-start-date')
                span.fp-text.-text-xs.-color-danger {{ t('giveawayForm.invalidStartDate') }}
              .fp-vertical-spacing--line.-align-right(v-if='!isValidEndDate' data-test='invalid-end-date')
                span.fp-text.-text-xs.-color-danger {{ t('giveawayForm.invalidEndDate') }}
              template(v-if='isValidStartDate && isValidEndDate')
                .fp-vertical-spacing--line.-align-right(v-if='!anteriorEndDate' data-test='end-date-in-past')
                  span.fp-text.-text-xs.-color-danger {{ t('giveawayForm.earlierEndDate') }}
                .fp-vertical-spacing--line.-align-right(v-if='!startDateBeforeEndDate' data-test='end-date-before-start-date')
                  span.fp-text.-text-xs.-color-danger {{ t('giveawayForm.endDateBeforeStartDate') }}
          .fp-vertical-spacing--line
            .fp-responsive-grid.-cols2.-cols1-up-md
              .fp-responsive-grid--column
                label.fp-field
                  | {{ t('giveawayForm.partner.label') }}
                  +fp-select
                    select(v-model='giveawayForm.partner' name='partner' data-test='partner' :disabled='limitedUpdate')
                      option(v-for='(partner, index) in partners' :key='index' :value='partner' v-text='partner.name' :data-test='`partner.${partner.slug}`')
              .fp-responsive-grid--column
                label.fp-field
                  | {{ t('giveawayForm.url') }}
                  input.fp-input-text(v-model='giveawayForm.url' type='text' :disabled='limitedUpdate' data-test='url')
    .fp-vertical-spacing--line(v-for='(question, questionIdx) in giveawayForm.questions' :key='`question${question.randomKey}`' :id='`giveaway-form-question-${question.randomKey}`' data-test='questions')
      GiveawayFormQuestionVue(:question='question' :question-index='questionIdx' :is-last-question='questionIdx === giveawayForm.questions.length - 1' :limited-update='limitedUpdate' @remove-question='removeQuestion(questionIdx)' @clone-question='cloneQuestion(questionIdx)' @up='moveQuestionUp(questionIdx)' @down='moveQuestionDown(questionIdx)' @question-updated='updateQuestion')
    .fp-vertical-spacing--line(v-if='!limitedUpdate')
      a.fp-link(data-test='add-question' @click='addGiveawayQuestion')
        span.fp-link--icon
          i.fp-icon.fp-glyph-plus.-s16
        | {{ t('giveawayForm.addQuestion') }}
