.fp-vertical-spacing.-s-lg
  .fp-vertical-spacing--line
    .fp-responsive-grid.-cols2.-cols1-up-md
      .fp-responsive-grid--column
        .fp-card.-highlighted
          .fp-vertical-spacing(v-if='!auction.isLoading()')
            .fp-vertical-spacing--line
              .fp-vertical-spacing
                .fp-vertical-spacing--line
                  .fp-horizontal-spacing.-items-center.-s-md
                    .fp-horizontal-spacing--column.-expand
                      .fp-text.-text-lg.-text-semi-bold(data-test='name') {{ auctionUi.name }}
                    .fp-horizontal-spacing--column
                      span.fp-tag(:class='auctionUi.statusTagClass' data-test='status-tag') {{ t(`auctionsPage.status.${auctionUi.status}`) }}
                    .fp-horizontal-spacing--column(v-if='auctionUi.status !== "CLOSED"')
                      button.fp-icon.fp-glyph-edit.-s16.-pointer(@click='openUpdateAuctionModal' data-test='update-auction' :title='t(\'auctionsPage.edit\')')
                .fp-vertical-spacing--line
                  .fp-vertical-spacing.-s-sm
                    .fp-vertical-spacing--line
                      .fp-icon-with-text(:title='auctionUi.startDate.toHuman()' data-test='start-date')
                        i.fp-icon.fp-glyph-calendar-check
                        span.fp-text.-text-semi-bold {{ t('auctionsPage.start') }} :
                        RelativeTimeVue(:date='auction.value().startDate')
                    .fp-vertical-spacing--line
                      .fp-icon-with-text(:title='auctionUi.endDate.toHuman()' data-test='end-date')
                        i.fp-icon.fp-glyph-calendar-x
                        span.fp-text.-text-semi-bold {{ t('auctionsPage.end') }} :
                        RelativeTimeVue(:date='auction.value().endDate')
                .fp-vertical-spacing--line
                  .fp-icon-with-text(v-if='auctionUi.anonymous' data-test='anonymous')
                    i.fp-icon.fp-glyph-eye-slash
                    span.fp-text {{ t('auctionsPage.anonymousAuction') }}
                  .fp-icon-with-text(v-if='!auctionUi.anonymous' data-test='non-anonymous')
                    i.fp-icon.fp-glyph-eye
                    span.fp-text {{ t('auctionsPage.nonAnonymousAuction') }}
                .fp-vertical-spacing--line
                  .fp-icon-with-text(v-if='auctionUi.useLotNumbers' data-test='use-lot-numbers')
                    i.fp-icon.fp-glyph-tag
                    span.fp-text {{ t('auctionsPage.useLotNumbersAuction') }}
                  .fp-icon-with-text(v-if='!auctionUi.useLotNumbers' data-test='not-use-lot-numbers')
                    i.fp-icon.fp-glyph-close
                    span.fp-text {{ t('auctionsPage.notUseLotNumbersAuction') }}
      .fp-responsive-grid--column
        AuctionStatisticsVue(:auction-id='auctionId' :bids='bids')
  .fp-vertical-spacing--line
    AuctionBidsTableVue(:bids='bids')
