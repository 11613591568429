.fp-layout
  .fp-layout--body
    .fp-page
      .fp-page--content
        .fp-page--content-wrapper
          .fp-vertical-spacing(v-if='!clubSummaries.isLoading()')
            .fp-vertical-spacing--line(v-for='(club, index) in clubSummaries.value()' :key='index')
              router-link.fp-link.no-text-decoration(:to='{ name: \'clubOverview\', params: { clubSlug: club.slug } }' :data-test='`club-selector-${index}`')
                .fp-decorated-listing-element
                  .fp-decorated-listing-element--logo
                    img(:src='club.logoUrl')
                  .fp-decorated-listing-element--text
                    span.fp-text.-text-2xl.-text-semi-bold(v-text='club.platformName')
                  .fp-decorated-listing-element--banner
                    img(:src='club.bannerUrl')
