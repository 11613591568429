import { InjectionKey } from 'vue';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { ModalOpened } from '@/common/domain/modal/ModalOpened';
import { BusCallback } from '@/common/domain/BusCallback';

export const modalBusKey: InjectionKey<ModalBus> = Symbol();

export interface ModalBus {
  open<T>(modal: ModalOpened<T>): void;
  close(): void;
  opened(callback: BusCallback<ModalOpened<any>>): Unsubscribe;
  closed(callback: BusCallback<void>): Unsubscribe;
}
