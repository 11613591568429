import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { ExclusiveSummary } from '@/staff/domain/exclusive/ExclusiveSummary';

export interface BackendExclusiveSummary {
  id: string;
  name: string;
  imageUrl: string;
  price: BackendPrice;
  slug: string;
}

export const toExclusiveSummary = (backendExclusive: BackendExclusiveSummary): ExclusiveSummary => ({
  id: backendExclusive.id,
  name: backendExclusive.name,
  imageUrl: backendExclusive.imageUrl,
  pricing: toTokens(backendExclusive.price),
  slug: backendExclusive.slug,
});
