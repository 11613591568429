.fp-staff-layout
  .fp-staff-layout--modal
    .fp-modal
      form.fp-vertical-spacing.-s-xl#signup-form(v-if='!twoFactorNeeded' @submit.prevent='signUp' data-test='signup-form')
        .fp-vertical-spacing--line.-align-center
          h1.fp-title(data-test='title') {{ t('login.newAccount') }}
        .fp-vertical-spacing--line
          button.fp-button.-expand.-secondary(@click='googleLogin' type='button' data-test='google-login')
            span.fp-button--icon
              img.fp-illustration.-xs.-google-logo
            | {{ t('login.googleButton') }}
        .fp-vertical-spacing--line
          .fp-separator
            .fp-separator--text
              .fp-separator--text {{ t('login.or') }}
        .fp-vertical-spacing--line
          .fp-vertical-spacing
            .fp-vertical-spacing--line
              .fp-icons-input
                label.fp-icons-input--left-icon(for='username')
                  i.fp-icon.fp-glyph-mail.-color-shade-500.-s16
                input.fp-input-text#username(v-model='signupForm.email' type='email' :placeholder=`t('login.email')` autocomplete='username' data-test='email' required)
            NewPasswordVue(@updated='updateNewPassword')
            .fp-vertical-spacing--line.-align-center(v-if='invalidSignup')
              span.fp-text.-color-danger.-paragraph(data-test='invalid-signup') {{ t('login.invalidSignup') }}
            .fp-vertical-spacing--line
              FairplayerButtonVue(icon='log-in' :text=`t('login.signUpButton')` type='submit' form='signup-form' :disabled='disabledSignup' :loading='isSigningUp' expand data-test='signup')
            .fp-vertical-spacing--line.-align-center
              span.fp-text.-color-shade-500.-text-sm
                | {{ t('login.hasAccount') }}&nbsp;
                router-link.fp-link.-secondary(:to='{name: \'login\'}' data-test='login-view') {{ t('login.loginView') }}
      EmailConfirmationVue(v-if='twoFactorNeeded' :credential='toCredential(signupForm)')
