import { computed, defineComponent, inject, onMounted, onUnmounted, PropType, ref, watch } from 'vue';
import { FairplayerButtonVue } from '@/common/primary/button';
import { useI18n } from 'vue-i18n';
import { GiveawayFormUi, hasGiveawayFormChanged } from '@/staff/primary/giveaway/GiveawayForm.ui';
import { Optional } from '@/common/domain/Optional';
import { cloneQuestionFormUi, emptyQuestionFormUi, GiveawayQuestionFormUi } from '@/staff/primary/giveaway/GiveawayQuestionForm.ui';
import { fromPartner, nonePartner, PartnerUi } from '@/staff/primary/giveaway/giveaways-table/Partner.ui';
import { ClubRepository, clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { Club } from '@/staff/domain/club/Club';
import { GiveawayFormQuestionVue } from '@/staff/primary/giveaway/giveaway-form/giveaway-form-question';
import { QuestionWithIndex } from '@/staff/primary/giveaway/giveaway-form/QuestionWithIndex';
import { globalWindowKey } from '@/common/domain/Window';
import { declareOnBeforeRouteLeave } from '@/common/primary/router/VueHooksDeclarator';
import { DateTimeInputUi } from '@/common/primary/date/DateTimeInput.ui';
import { sidebarBusKey } from '@/common/domain/sidebar/SidebarBus';
import { giveawayPreviewSidebar } from '@/common/primary/sidebar/Sidebars';
import { GiveawayPreviewSidebarOptions } from '@/staff/primary/giveaway/giveaway-form/giveaway-preview-sidebar/GiveawayPreviewSidebarOptions';
import { dragOver, dragStart, drop } from '@/staff/primary/club/club-form/DragDrop';

export default defineComponent({
  name: 'GiveawayForm',

  components: {
    GiveawayFormQuestionVue,
    FairplayerButtonVue,
  },

  props: {
    formValue: {
      type: Object as PropType<GiveawayFormUi>,
      required: true,
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
    isCloning: {
      type: Boolean,
      default: false,
    },
    limitedUpdate: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  emits: ['confirm', 'form-valid'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const clubRepository: ClubRepository = inject(clubRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const sidebarBus = inject(sidebarBusKey)!;

    const club: Club = clubRepository.getCurrentClub();
    const baseGiveawayUrl = `${club.webappUrl}/giveaway`;
    const partners: PartnerUi[] = [nonePartner(t), ...club.partners.map(fromPartner)];
    const giveawayForm = ref<GiveawayFormUi>(JSON.parse(JSON.stringify(props.formValue)));
    const askedCreate = ref<boolean>(false);
    const hasChanged = computed(() => hasGiveawayFormChanged(giveawayForm.value as GiveawayFormUi, props.formValue));

    const isValidDate = (date: Date) => !isNaN(date.getTime());

    const isValidStartDate = computed(() => isValidDate(DateTimeInputUi.toDate(giveawayForm.value.startDate as DateTimeInputUi)));
    const isValidEndDate = computed(() => isValidDate(DateTimeInputUi.toDate(giveawayForm.value.endDate as DateTimeInputUi)));

    const anteriorEndDate = computed(() => {
      const endDate = DateTimeInputUi.toDate(giveawayForm.value.endDate as DateTimeInputUi);
      const currentDate = new Date();
      return endDate > currentDate;
    });

    const startDateBeforeEndDate = computed(() => {
      const startDate = DateTimeInputUi.toDate(giveawayForm.value.startDate as DateTimeInputUi);
      const endDate = DateTimeInputUi.toDate(giveawayForm.value.endDate as DateTimeInputUi);
      return startDate < endDate;
    });

    const formValid = computed(
      () => isValidStartDate.value && isValidEndDate.value && anteriorEndDate.value && startDateBeforeEndDate.value
    );

    const computeGiveawayFormUrl = () => {
      giveawayForm.value.url = Optional.ofEmpty(giveawayForm.value.partner?.slug).isEmpty()
        ? baseGiveawayUrl
        : `${baseGiveawayUrl}?partner=${giveawayForm.value.partner.slug}`;
    };

    watch(
      () => [giveawayForm.value.partner],
      () => {
        if (giveawayForm.value.url.includes(baseGiveawayUrl)) {
          computeGiveawayFormUrl();
        }
      }
    );

    watch([giveawayForm.value], () => {
      emit('form-valid', { value: formValid.value });
    });

    onMounted(() => {
      const sidebar = giveawayPreviewSidebar();
      sidebarBus.open<GiveawayPreviewSidebarOptions>({
        title: 'Aperçu',
        component: sidebar,
        isClosable: false,
        options: {
          giveawayForm: giveawayForm.value as GiveawayFormUi,
        },
      });
      globalWindow.onbeforeunload = () => {
        if (hasChanged.value && !askedCreate.value) {
          return t('confirmLeaving');
        }
      };

      if (props.isUpdating) {
        return;
      }

      if (props.isCloning) {
        return;
      }

      setDefaultUrlAndPartner();
    });

    onUnmounted(() => {
      globalWindow.onbeforeunload = () => {};
    });

    const routeLeaveGuard = () => {
      if (hasChanged.value && !askedCreate.value) {
        return globalWindow.confirm(t('confirmLeaving'));
      }
    };

    declareOnBeforeRouteLeave(routeLeaveGuard);

    const setDefaultUrlAndPartner = () => {
      giveawayForm.value.url = baseGiveawayUrl;
      giveawayForm.value.partner = partners[0];
    };

    const addGiveawayQuestion = (): void => {
      giveawayForm.value.questions = [...giveawayForm.value.questions, emptyQuestionFormUi()];
    };

    const removeQuestion = (questionIdx: number): void => {
      giveawayForm.value.questions = giveawayForm.value.questions.filter((question, idx) => idx !== questionIdx);
    };

    const cloneQuestion = (questionIdx: number): void => {
      const question: GiveawayQuestionFormUi = cloneQuestionFormUi(giveawayForm.value.questions[questionIdx]);

      giveawayForm.value.questions = [
        ...giveawayForm.value.questions.slice(0, questionIdx + 1),
        question,
        ...giveawayForm.value.questions.slice(questionIdx + 1),
      ];
    };

    const updateQuestion = (questionWithIndex: QuestionWithIndex) => {
      giveawayForm.value.questions[questionWithIndex.index] = questionWithIndex.question;
    };

    const confirm = () => {
      if (!props.isUpdating) {
        askedCreate.value = true;
      }
      emit('confirm', giveawayForm.value);
    };

    const moveQuestionUp = (questionIndex: number) => {
      if (questionIndex === 0) {
        return;
      }
      const previousQuestion = giveawayForm.value.questions[questionIndex - 1];
      giveawayForm.value.questions[questionIndex - 1] = giveawayForm.value.questions[questionIndex];
      giveawayForm.value.questions[questionIndex] = previousQuestion;
      scrollInto(`giveaway-form-question-${giveawayForm.value.questions[questionIndex].randomKey}`);
    };

    const moveQuestionDown = (questionIndex: number) => {
      if (questionIndex === giveawayForm.value.questions.length - 1) {
        return;
      }
      const nextQuestion = giveawayForm.value.questions[questionIndex + 1];
      giveawayForm.value.questions[questionIndex + 1] = giveawayForm.value.questions[questionIndex];
      giveawayForm.value.questions[questionIndex] = nextQuestion;
      scrollInto(`giveaway-form-question-${giveawayForm.value.questions[questionIndex].randomKey}`);
    };

    const scrollInto = (elementId: string) => {
      globalWindow.document.getElementById(elementId)!.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    };

    const onDragStart = (event: DragEvent) => dragStart(event, giveawayForm.value);

    const onDragOver = async (event: DragEvent) => dragOver(event);

    const onDrop = async (event: DragEvent) => {
      drop<GiveawayFormUi>(event).ifPresent(value => (giveawayForm.value = value));
    };

    return {
      addGiveawayQuestion,
      confirm,
      updateQuestion,
      giveawayForm,
      partners,
      onDragStart,
      onDragOver,
      onDrop,
      cloneQuestion,
      removeQuestion,
      routeLeaveGuard,
      moveQuestionUp,
      moveQuestionDown,
      isValidDate,
      isValidStartDate,
      isValidEndDate,
      anteriorEndDate,
      startDateBeforeEndDate,
      t,
    };
  },
});
