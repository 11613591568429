import { Club } from '@/staff/domain/club/Club';
import { Language } from '@/common/domain/Language';
import { ClubToUpdate } from '@/staff/domain/club/ClubToUpdate';
import { Optional } from '@/common/domain/Optional';
import { emptyTranslations, fromTranslations, fromTranslationUi, TranslationUi } from '@/common/primary/Translation.ui';
import { TranslatableField } from '@/common/domain/TranslatableField';
import { Translation } from '@/common/domain/Translation';

export interface ClubFormUi {
  presentationTranslations: TranslationUi[];
}

export const fromClub = (club: Club, languages: Language[]): ClubFormUi => ({
  presentationTranslations: fromTranslations(languages, club.presentationTranslations!),
});

export const toClubToUpdate = (clubForm: ClubFormUi, club: Club): ClubToUpdate => ({
  slug: club.slug,
  presentationTranslations: toUpdatedTranslations(club.presentationTranslations!, clubForm.presentationTranslations),
});

const toUpdatedTranslations = <T extends TranslatableField>(
  oldTranslations: Translation<T>[],
  uiTranslations: TranslationUi[]
): Optional<Translation<T>[]> =>
  JSON.stringify(uiTranslations).localeCompare(JSON.stringify(oldTranslations)) !== 0
    ? Optional.of(uiTranslations.filter(t => t.value).map(fromTranslationUi<T>))
    : Optional.empty();

export const emptyClubForm = (languages: string[]): ClubFormUi => ({
  presentationTranslations: emptyTranslations(languages),
});

export const hasClubFormChanged = (clubForm: ClubFormUi, comparedClub: ClubFormUi) =>
  JSON.stringify(clubForm) !== JSON.stringify(comparedClub);
