import { fromMember, MemberUi } from '@/staff/primary/fan/Member.ui';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { BidWithExclusive } from '@/staff/domain/exclusive/BidWithExclusive';
import { DateUi } from '@/common/primary/date/Date.ui';
import { ExclusiveSummaryUi, fromExclusiveSummary } from '@/staff/primary/auction/auction-details/ExclusiveSummary.ui';

export interface BidWithExclusiveUi {
  id: string;
  bidder: MemberUi;
  price: TokensUi;
  date: DateUi;
  exclusive: ExclusiveSummaryUi;
}

export const fromBidWithExclusive = (bid: BidWithExclusive): BidWithExclusiveUi => ({
  id: bid.id,
  bidder: fromMember(bid.bidder),
  price: fromTokens(bid.price),
  date: new DateUi(bid.date),
  exclusive: fromExclusiveSummary(bid.exclusive),
});
