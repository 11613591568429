.fp-responsive-grid.-cols4.-cols2-up-md.-cols1-up-sm
  .fp-responsive-grid--column
    .fp-card.-dark
      .fp-vertical-spacing.-s-xs.-justify-center
        .fp-vertical-spacing--line.-justify-center
          .fp-logo
            img(:src='club.logoUrl')
        .fp-vertical-spacing--line.-align-center
          .fp-text.-text-xl.-text-bold.-color-shade-0 {{ club.platformName }}
  template(v-if='showStatistics')
    .fp-responsive-grid--column(data-test='sales-amount')
      .fp-card.-highlighted
        .fp-vertical-spacing.-s-xs.-justify-center.-h100
          .fp-vertical-spacing--line.-align-center
            i.fp-icon.fp-glyph-wallet.-s24.-color-shade-500
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-semi-bold.-text-2xl(v-if='!globalStatistics.isLoading()') {{ globalStatistics.value().salesAmount.text }}
            .fp-placeholder.-height-sm(v-else)
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-semi-bold.-text-sm(v-if='club.isMarketplace' data-test='sales-amount-title') {{ t('home.globalStatistics.salesAmount.title') }}
            span.fp-text.-text-semi-bold.-text-sm(v-else data-test='funds-raised-title') {{ t('home.globalStatistics.fundsRaised.title') }}
    FansStatisticsVue
