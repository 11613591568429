import { computed, ComputedRef, defineComponent, inject, onMounted, onUnmounted, PropType, ref, toRaw } from 'vue';
import { useI18n } from 'vue-i18n';
import { MarkdownEditorVue } from '@/common/primary/markdown/editor';
import { ClubFormUi, hasClubFormChanged } from '@/staff/primary/club/club-form/ClubForm.ui';
import { globalWindowKey } from '@/common/domain/Window';
import { declareOnBeforeRouteLeave } from '@/common/primary/router/VueHooksDeclarator';
import { renderMarkdown } from '@/common/primary/markdown/MarkdownRenderer';
import { Language } from '@/common/domain/Language';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { translationFor, TranslationUi } from '@/common/primary/Translation.ui';
import { dragOver, dragStart, drop } from '@/staff/primary/club/club-form/DragDrop';

export default defineComponent({
  name: 'ClubForm',

  components: { MarkdownEditorVue },

  props: {
    slug: {
      type: String,
      required: true,
    },
    isUpdating: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    formValue: {
      type: Object as PropType<ClubFormUi>,
      required: true,
    },
  },

  emits: ['confirm'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const globalWindow = inject(globalWindowKey)!;
    const clubRepository = inject(clubRepositoryKey)!;

    const clubForm = ref<ClubFormUi>(structuredClone(toRaw(props.formValue)));
    const askedCreate = ref<boolean>(false);
    const isTranslationLoading = ref<boolean>(false);
    const hiddenPreviews = ref<Record<string, boolean>>({});
    const previewVisibleFor = (translation: TranslationUi) => !hiddenPreviews.value[translation.language];
    const hasPresentation = computed(() => clubForm.value.presentationTranslations[0].value.length > 0);

    const formatPresentation = (presentation: string) => renderMarkdown(presentation) as string;

    const hasFormChanged = () => computed(() => hasClubFormChanged(clubForm.value as ClubFormUi, props.formValue));

    onMounted(async () => {
      globalWindow.onbeforeunload = () => {
        if (hasFormChanged().value && !askedCreate.value) {
          return t('confirmLeaving');
        }
      };
    });

    onUnmounted(() => {
      globalWindow.onbeforeunload = () => {};
    });

    const routeLeaveGuard = () => {
      if (hasFormChanged().value && !askedCreate.value) {
        return globalWindow.confirm(t('confirmLeaving'));
      }
    };

    declareOnBeforeRouteLeave(routeLeaveGuard);

    const confirm = () => {
      if (!props.isUpdating) {
        askedCreate.value = true;
      }
      emit('confirm', clubForm.value);
    };

    const resetPresentationTranslation = (language: Language) => {
      translationFor(clubForm.value.presentationTranslations, language).value = translationFor(
        props.formValue.presentationTranslations,
        language
      ).value;
    };

    const hasPresentationTranslationChanged = (translation: TranslationUi) =>
      translation.value !== translationFor(props.formValue.presentationTranslations, translation.language).value;

    const togglePreviewFor = (translation: TranslationUi) => {
      hiddenPreviews.value[translation.language] = !hiddenPreviews.value[translation.language];
    };

    const updateTranslation = (translation: TranslationUi, updatedPresentation: string) => {
      translation.value = updatedPresentation;
    };

    const translateText = async (targetLanguage: string, translations: TranslationUi[], hasTranslation: ComputedRef<boolean>) => {
      if (hasTranslation.value && !isTranslationLoading.value) {
        isTranslationLoading.value = true;
        const frenchTranslation = translationFor(translations, 'fr');
        const translation = await clubRepository.translateText({
          text: frenchTranslation.value,
          sourceLanguage: 'fr',
          targetLanguage,
          clubSlug: props.slug,
        });
        const index = translations.findIndex(translation => translation.language === targetLanguage);
        translations.splice(index, 1, {
          ...translations[index],
          value: translation,
        });
        isTranslationLoading.value = false;
      }
    };

    const translatePresentation = (targetLanguage: string) =>
      translateText(targetLanguage, clubForm.value.presentationTranslations, hasPresentation);

    const onDragStart = (event: DragEvent) => dragStart(event, clubForm.value);

    const onDragOver = async (event: DragEvent) => dragOver(event);

    const onDrop = async (event: DragEvent) => {
      drop<ClubFormUi>(event).ifPresent(value => (clubForm.value = value));
    };

    return {
      t,
      confirm,
      clubForm,
      translateText,
      onDragStart,
      onDragOver,
      onDrop,
      hasPresentation,
      translatePresentation,
      isTranslationLoading,
      routeLeaveGuard,
      togglePreviewFor,
      updateTranslation,
      previewVisibleFor,
      formatPresentation,
      resetPresentationTranslation,
      hasPresentationTranslationChanged,
    };
  },
});
