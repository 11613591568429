import { ExclusiveSummary } from '@/staff/domain/exclusive/ExclusiveSummary';

export interface ExclusiveSummaryUi {
  id: string;
  name: string;
  imageUrl: string;
  slug: string;
}

export const fromExclusiveSummary = (exclusive: ExclusiveSummary): ExclusiveSummaryUi => ({
  id: exclusive.id,
  name: exclusive.name,
  imageUrl: exclusive.imageUrl,
  slug: exclusive.slug,
});
