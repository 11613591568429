include ../../../../../common/primary/select/select.mixin.pug

.fp-card.-highlighted(:data-test='`question-${questionIndex}`')
  .fp-horizontal-spacing
    .fp-horizontal-spacing--column.-expand
      .fp-vertical-spacing
        .fp-vertical-spacing--line(v-if='isPreTitleShown')
          MarkdownEditorVue(:text='questionToUpdate.preTitle' @updated-value='preTitle => updatePreTitle(preTitle)' data-test='pre-title')
        .fp-vertical-spacing--line
          .fp-horizontal-spacing.-s-sm
            .fp-horizontal-spacing--column.-expand
              input.fp-input-text(v-model='questionToUpdate.title' type='text' required :placeholder='t(\'giveawayForm.question\')' data-test='title')
            .fp-horizontal-spacing--column
              +fp-select
                select(v-model='questionToUpdate.type' name='type' data-test='type' :disabled='limitedUpdate')
                  option(v-for='questionType in questionTypes' :key='`${questionType}`' :value='questionType' v-text='t(`giveawayForm.type.${questionType.toLowerCase()}`)' :data-test='`${questionType.toLowerCase()}`')
        template(v-if='questionToUpdate.type === \'CHOICE\'')
          .fp-vertical-spacing--line(v-for='(answer, answerIdx) in questionToUpdate.answers' :key='`answer-${answerIdx}`' data-test='answers')
            .fp-horizontal-spacing.-s-sm.-items-center(:data-test='`answer-${answerIdx}`')
              .fp-horizontal-spacing--column
                .fp-vertical-spacing.-s-xs
                  .fp-vertical-spacing--line
                    button.fp-icon.fp-glyph-chevron-up.-s12(@click='moveAnswerUp(answerIdx)' :disabled='answerIdx === 0' :class='answerIdx === 0 ? "-color-shade-300" : "-pointer"' :data-test='`move-up-answer-${answerIdx}`' type='button')
                  .fp-vertical-spacing--line
                    button.fp-icon.fp-glyph-chevron-down.-s12(@click='moveAnswerDown(answerIdx)' :disabled='answerIdx === question.answers.length - 1' :class='answerIdx === question.answers.length - 1 ? "-color-shade-300" : "-pointer"' :data-test='`move-down-answer-${answerIdx}`' type='button')
              .fp-horizontal-spacing--column(v-if='question.answers.length > MINIMAL_QUESTION_ANSWERS && !limitedUpdate')
                button.fp-icon.fp-glyph-trash.-s16.-pointer(@click='removeAnswer(answerIdx)' :data-test='`remove-answer-${answerIdx}`' :title='t(\'giveawayForm.removeAnswer\')' type='button')
              .fp-horizontal-spacing--column.-expand
                input.fp-input-text(v-model='answer.text' type='text' required :placeholder='`${t(\'giveawayForm.answer\')} ${answerIdx + 1}`' :data-test='`answer-${answerIdx}.text`')
          .fp-vertical-spacing--line(v-if='!limitedUpdate')
            a.fp-link(data-test='add-answer' @click='addAnswer')
              span.fp-link--icon
                i.fp-icon.fp-glyph-plus.-s16
              | {{ t('giveawayForm.addAnswer') }}
          .fp-vertical-spacing--line
            .fp-horizontal-spacing.-items-center
              .fp-horizontal-spacing--column
                .fp-checkable-element
                  .fp-checkable-element--checkbox
                    input.fp-checkbox(:id='`question-${questionIndex}.quiz-mode`' v-model='questionToUpdate.quizMode' type='checkbox' data-test='quiz-mode' :disabled='limitedUpdate')
                  .fp-checkable-element--label
                    label(:for='`question-${questionIndex}.quiz-mode`') {{ t('giveawayForm.quizMode') }}
              .fp-horizontal-spacing--column.-expand(v-if='questionToUpdate.quizMode')
                +fp-select
                  select(v-model='questionToUpdate.rightAnswer' name='rightAnswer' data-test='right-answer' required)
                    option(:value='undefined' v-text='t(\'giveawayForm.rightAnswerUndefined\')' data-test='option-undefined')
                    option(v-for='(answer, index) in questionToUpdate.answers' :key='`option-${index}`' :value='answer' v-text='answer.text || `${t(\'giveawayForm.answer\')} ${index + 1}`' :data-test='`option-${index}`')
        .fp-vertical-spacing--line
          .fp-checkable-element
            .fp-checkable-element--checkbox
              input.fp-checkbox(:id='`question-${questionIndex}.required`' v-model='questionToUpdate.required' type='checkbox' data-test='required' :disabled='limitedUpdate')
            .fp-checkable-element--label
              label(:for='`question-${questionIndex}.required`') {{ t('giveawayForm.answerRequired') }}
    .fp-horizontal-spacing--column
      .fp-vertical-spacing.-s-sm
        .fp-vertical-spacing--line
          button.fp-icon.fp-glyph-chevron-up.-s16(@click='moveQuestionUp' :disabled='questionIndex === 0' :class='questionIndex === 0 ? "-color-shade-300" : "-pointer"' data-test='move-up-question' type='button')
        .fp-vertical-spacing--line
          button.fp-icon.fp-glyph-chevron-down.-s16(@click='moveQuestionDown' :disabled='isLastQuestion' :class='isLastQuestion ? "-color-shade-300" : "-pointer"' data-test='move-down-question' type='button')
        .fp-vertical-spacing--line
          button.fp-icon.fp-glyph-list-plus.-s16.-pointer(v-if='!isPreTitleShown' @click='showPreTitle' :title='t(\'giveawayForm.showPreTitle\')' data-test='show-pre-title' type='button')
          button.fp-icon.fp-glyph-list-minus.-s16.-pointer(v-else @click='hidePreTitle' :title='t(\'giveawayForm.hidePreTitle\')' data-test='hide-pre-title' type='button')
        .fp-vertical-spacing--line
          button.fp-icon.fp-glyph-copy.-s16.-pointer(@click='cloneQuestion' :title='t(\'giveawayForm.cloneQuestion\')' data-test='clone-question' type='button')
        .fp-vertical-spacing--line(v-if='!limitedUpdate')
          button.fp-icon.fp-glyph-trash.-s16.-pointer(@click='removeQuestion'  :title='t(\'giveawayForm.removeQuestion\')' data-test='remove-question' type='button')
