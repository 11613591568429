.fp-card.-highlighted(data-test='auction-card' @click='onAuctionCardClick')
  .fp-horizontal-spacing.-items-center.-s-sm
    .fp-horizontal-spacing--column
      input.fp-radio(
        type='radio'
        name='auction'
        :value='auctionUi.id'
        :checked='isSelected')
    .fp-horizontal-spacing--column.-expand
      .fp-text.-text-semi-bold.-lines-1 {{ auctionUi.name }}
    .fp-horizontal-spacing--column
      span.fp-tag(:class='auctionUi.statusTagClass' data-test='status-tag') {{ t(`auctionsPage.status.${auctionUi.status}`) }}
