import { ProgramToUpdate } from '@/staff/domain/program/ProgramToUpdate';
import { BackendTranslation, toBackendTranslation } from '@/common/secondary/BackendTranslation';
import { Optional } from '@/common/domain/Optional';
import { Translation } from '@/common/domain/Translation';
import { TranslatableField } from '@/common/domain/TranslatableField';

export interface BackendProgramToUpdate {
  slug?: string;
  imageUrl?: string;
  nameTranslations?: BackendTranslation[];
  descriptionTranslations?: BackendTranslation[];
}

export const toBackendProgramToUpdate = (programToUpdate: ProgramToUpdate): BackendProgramToUpdate => ({
  slug: programToUpdate.slug.orElseUndefined(),
  imageUrl: programToUpdate.imageUrl.orElseUndefined(),
  nameTranslations: toBackendTranslations(programToUpdate.nameTranslations),
  descriptionTranslations: toBackendTranslations(programToUpdate.descriptionTranslations),
});

const toBackendTranslations = <T extends TranslatableField>(translations: Optional<Translation<T>[]>): BackendTranslation[] | undefined => {
  if (translations.isEmpty()) {
    return undefined;
  }

  return translations.map(actualValues => actualValues.map(toBackendTranslation<T>)).orElseUndefined();
};
